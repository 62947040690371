import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import {
  FontIcon,
  Media,
  Paper,
  SelectField,
  Tab,
  Tabs,
  TabsContainer,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import { getAvailableTemplates } from 'components/Cards/CardTemplates'
import HfSwitchForm from 'components/HfSwitchForm'
import IconChooser from 'components/IconChooser'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import {
  hostUIMessages,
  prefixFields,
  tokenFields
} from 'constants/UIConstants'
import { copyItem, fetchItems, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { fixRotation } from 'redux/modules/filestack'
import currentUser from 'utils/CurrentUser'
import { sortData } from 'utils/Data'
import { fieldHasContent, requiredIf } from 'utils/ValidationHelpers'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class InformationForm extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    // find what templates are available
    this.availableTemplates = getAvailableTemplates()
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.imageUploaded = this.imageUploaded.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, ownerPluralName, pluralName, guidebookId } = this.props

    if (guidebookId) {
      const path = '#informations#information'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  handleTabChange(template) {
    const { dispatch } = this.props
    dispatch(actions.change('edit_information.template', template))
  }

  imageUploaded(uploadResult) {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(actions.change('edit_information.image', data.url))
        }
      })
    }
  }

  handleCategoryChange(value) {
    const { dispatch } = this.props
    if (value === 0) {
      dispatch(actions.change('edit_information.category', null))
    } else {
      dispatch(actions.change('edit_information.category.id', value))
    }
  }

  formatMessage = this.props.intl.formatMessage

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_information'))
  }

  handleVideoChange = (value) => {
    const { dispatch } = this.props
    let updatedValue = value
    // if the value is the whole embed code, grab just the src field
    if (value.trim().substring(0, 7) === '<iframe') {
      let parts = value.trim().split(' ')
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].substring(0, 3) === 'src') {
          let srcPart = parts[i].substring(5, parts[i].length - 1)
          updatedValue = srcPart
        }
      }
    }
    // remove any quotes
    updatedValue = updatedValue.replace(/"/g, '')

    // if the video is just a regular youtube link, go ahead and convert it for them
    if (
      updatedValue.indexOf('youtu') !== -1 &&
      updatedValue.indexOf('embed') === -1
    ) {
      const shortlinkIdx = updatedValue.indexOf('youtu.be')
      const regularLinkIdx = updatedValue.indexOf('youtube.com/watch?v')
      let videoSlug = null
      if (shortlinkIdx !== -1) {
        videoSlug = updatedValue.substring(
          shortlinkIdx + 9,
          shortlinkIdx + 9 + 11
        )
      } else if (regularLinkIdx !== -1) {
        videoSlug = updatedValue.substring(
          regularLinkIdx + 20,
          regularLinkIdx + 20 + 11
        )
      }
      // replace the value with the embed code that we really want
      updatedValue = 'https://www.youtube.com/embed/' + videoSlug
    }

    dispatch(actions.change('edit_information.video', updatedValue))
    dispatch(actions.resetValidity('edit_information.video'))
    dispatch(actions.setTouched('edit_information.video'))
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem(
        'informations',
        'information',
        id,
        item,
        addAnother,
        currentGuidebook
      )
    )
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    for (var field in formErrors) {
      if (edit_form[field] && !edit_form[field].valid) {
        return false
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_information'))
  }

  componentDidMount() {
    const { dispatch, itemId, guidebookId, ownerPluralName } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.information.data
      if (guidebookId && guidebookId !== 'create') {
        // if creating from a guidebook/template, select the guidebook by default
        var include = {}
        include[ownerPluralName] = [{ id: guidebookId }]
        newItem = Object.assign({}, newItem, include)
      }
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_information', newItem))
    }
    dispatch(fetchItems('categories'))
  }

  renderFields = (formErrors) => {
    const { edit_information, categories } = this.props
    const locales = edit_information.locales
    const image = edit_information.image || ''
    const video = edit_information.video || ''
    const template = edit_information.template
    const fields = []
    fields.push(
      <div key="card-title" className="hf-headline-margin">
        <h2 className="md-headline">Give your card a title</h2>
        <p className="md-body-1">
          Your guests will see this heading at the top of the card *
        </p>
        <MultilangWysiwyg
          simpleEditor
          max_length={140}
          model="edit_information"
          field="title"
          txn_field="title_txn"
          locales={locales}
        />
        {formErrors['title']}
      </div>
    )
    if (!edit_information.meta_card) {
      fields.push(
        <div key="card-icon">
          <h2 className="md-headline">Choose an icon</h2>
          <div className="hf-editable-grid-lists hf-wider-selection-control-container">
            <IconChooser editModel="edit_information" editField="icon" />
          </div>
          {formErrors['icon']}
        </div>
      )
      const sortedCategories = sortData(categories.data, 'order')
        .filter((cat) => {
          return cat.type === 'house manual'
        })
        .map((cat) => {
          const catName =
            cat.name.length > 0
              ? cat.name.charAt(0).toUpperCase() + cat.name.slice(1)
              : cat.name
          return { id: cat.id, name: catName }
        })
      sortedCategories.push({
        id: 0,
        name: '-- None: Use Default House Manual Tab --'
      })

      if (sortedCategories.length > 1) {
        const selectedCategory = edit_information.category
          ? edit_information.category.id
          : 0
        fields.push(
          <div key="card-category">
            <h2 className="md-headline">Choose a house manual category</h2>
            <p className="md-body-1">
              If you choose a category here, your card will appear on one of your
              custom house manual tabs insted of the main house manual tab
            </p>
            <SelectField
              id="selectDefaultPath"
              className="md-cell md-cell--9 md-cell--5-tablet"
              label="Category *"
              menuItems={sortedCategories}
              itemLabel="name"
              itemValue="id"
              onChange={this.handleCategoryChange}
              value={selectedCategory}
            />
          </div>
        )
      }
    }

    if (template === 0) {
      const filestackOptions = {
        accept: 'image/*',
        maxFiles: 1,
        storeTo: {
          location: 'gcs'
        },
        fromSources: ['local_file_system', 'url', 'imagesearch'],
        imageMax: [1400, 800],
        transformations: {
          crop: {
            aspectRatio: 7 / 4,
            force: true
          },
          rotate: true
        }
      }
      const filestackKey = process.env.REACT_APP_FILESTACK_KEY
      const preview_image = image ? (
        <Media aspectRatio="7-4">
          <img src={image} alt="" />
        </Media>
      ) : (
        <Media aspectRatio="7-4">
          <img
            src="https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png"
            alt=""
          />
        </Media>
      )

      fields.push(
        <div key="card-image" className="hf-headline-margin">
          <h2 className="md-headline">Upload a photo</h2>
          <div className="hf-editable-grid-lists hf-wider-selection-control-container">
            <div className="md-grid">
              <div className="md-cell md-cell--4">{preview_image}</div>
              <div className="md-cell md-cell--8">
                <PickerOverlay
                  apikey={filestackKey}
                  componentDisplayMode={{
                    type: 'button',
                    customText: 'Select Image',
                    customClass:
                      'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                  }}
                  actionOptions={filestackOptions}
                  onSuccess={this.imageUploaded}
                />
              </div>
            </div>
          </div>
          {formErrors['image']}
        </div>
      )
    }

    if (template === 1) {
      const preview_video = video ? (
        <Media aspectRatio="16-9">
          <iframe
            title="house manual video preview"
            allowFullScreen
            src={video}
            frameBorder="0"
            seamless="seamless"
          />
        </Media>
      ) : (
        <Media aspectRatio="16-9">
          <iframe
            title="house manual video preview"
            allowFullScreen
            src="https://www.youtube.com/embed/lJIrF4YjHfQ?modestbranding=1&start=11&rel=0&showinfo=0"
            frameBorder="0"
            seamless="seamless"
          />
        </Media>
      )

      fields.push(
        <div key="card-video" className="hf-headline-margin">
          <h2 className="md-headline">Embed a video</h2>
          <p className="md-body-1">
            Make sure you copy the source url from the embed code not just the
            share URL. For Youtube the url will include the word "embed" in it
            (eg "https://www.youtube.com/<b>embed</b>/wGQHQc_3ycE") and for
            Vimeo it will include the words "player" and "video" eg ("https://
            <b>player</b>.vimeo.com/<b>video</b>/99999999").
          </p>
          <div className="hf-editable-grid-lists hf-wider-selection-control-container">
            <div className="md-grid">
              <div className="md-cell md-cell--4">{preview_video}</div>
              <div className="md-cell md-cell--8">
                <TextField
                  id="video"
                  label="Video embed code"
                  placeholder="For example https://www.youtube.com/embed/wGQHQc_3ycE"
                  onChange={this.handleVideoChange}
                  value={video}
                />
                {formErrors['video']}
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (template === 2) {
      fields.push(
        <div key="card-lead-para" className="hf-headline-margin">
          <h2 className="md-headline">
            Concisely describe the content on this card
          </h2>
          <MultilangWysiwyg
            simpleEditor
            model="edit_information"
            field="lead_para"
            max_length={250}
            txn_field="lead_para_txn"
            locales={locales}
          />
          {formErrors['lead_para']}
        </div>
      )
    }

    return <div className="md-cell md-cell--12">{fields}</div>
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_information'))
  }

  render() {
    const self = this
    const { edit_information, itemId, guidebookId, copy, ownerPluralName } =
      this.props

    const locales = edit_information.locales
    const template = edit_information.template
    const title = itemId === 'create' ? 'New card' : 'Edit card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={this.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )

    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      name: {
        required: 'Please provide a name'
      },
      title: {
        required: 'Please provide a title',
        length: 'Please use a maximum of 140 characters in your title'
      },
      content: {
        required: 'Please provide some content'
      },
      external_link: {
        required: 'Please provide the link that this card will open',
        valid: 'Please enter a valid link (starting with https:// http://)'
      },
      icon: {
        required: 'Please select an icon'
      },
      video: {
        required:
          'Please paste in the embed url for a video or choose a different template',
        valid:
          'Please check that you have pasted the correct URL. Youtube (url contains the word "embed"), Vimeo (url contains the word "player") videos are supported. Matterport embeds will also work (url contains the word "show"). Be sure the link starts with http or https'
      },
      image: {
        required: 'Please upload an image or choose a different template'
      },
      lead_para: {
        required:
          'Please provide a lead paragraph for the card, or change to the icon template',
        length: 'Please use a maximum of 250 characters in your lead paragraph'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_information"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['name'] = (
      <Errors
        model=".name"
        messages={errorMessages.name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['title'] = (
      <Errors
        model=".title"
        messages={errorMessages.title}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['content'] = (
      <Errors
        model=".content"
        messages={errorMessages.content}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['external_link'] = (
      <Errors
        model=".external_link"
        messages={errorMessages.external_link}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['icon'] = (
      <Errors
        model=".icon"
        messages={errorMessages.icon}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['video'] = (
      <Errors
        model=".video"
        messages={errorMessages.video}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['image'] = (
      <Errors
        model=".image"
        messages={errorMessages.image}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['lead_para'] = (
      <Errors
        model=".lead_para"
        messages={errorMessages.lead_para}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const user = currentUser()
    const availableVariables = user.canUseSecurity
      ? prefixFields(tokenFields, 'token')
      : []

    if (edit_information.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            this.copyCard(edit_information.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }
    const tabsOutput = []

    for (var i = 0; i < this.availableTemplates.length; i++) {
      const templateName = this.availableTemplates[i].name
      const templateClass = this.availableTemplates[i].tabClass
      const help = this.availableTemplates[i].help
      const content = (
        <TextField
          id={templateName}
          label="Selected template"
          helpText={help}
          defaultValue={templateName}
          disabled
        />
      )

      tabsOutput.push(
        <Tab
          id={'card-' + templateName}
          key={templateName}
          className={templateClass}
          label={templateName}
        >
          {content}
        </Tab>
      )
    }

    const restrictAccess = user.canUseSecurity && !edit_information.meta_card ? (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2>Do you want to restrict access to this house manual card?</h2>
        <p>
          This will make this card only appear to guests viewing your guidebook
          with a secure link.&nbsp; (This is not necessary if your are
          restricing access to your entire guidebook using secure links.)&nbsp;
          <a className="hf-grey-link" target="_blank" href="/host/tokens">
            You can create and edit secure links here
          </a>
        </p>
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--1 md-cell--1-phone">
            <p className="md-text md-text-right hf-switch-left">No</p>
          </div>
          <div className="md-cell md-cell--1 md-cell--1-phone">
            <HfSwitchForm
              name="secure_access_only"
              editModel={'edit_information'}
              model="secure_access_only"
              checked={edit_information.secure_access_only}
              label="Yes"
              labelBefore
            />
          </div>
        </div>
      </div>
    ) : null

    const linkToggle = edit_information.meta_card ? null : (
      <div className="md-cell md-cell--12 hf-healdine-margin">
        <h2>
          Would you like this card to link to an external site instead of
          opening up to display content?
        </h2>
        <p>
          Instead of opening up to display content, this card will link to an
          external website in a new tab
        </p>
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--1 md-cell--1-phone">
            <p className="md-text md-text-right hf-switch-left">No</p>
          </div>
          <div className="md-cell md-cell--1 md-cell--1-phone">
            <HfSwitchForm
              name="use_external_link"
              editModel={'edit_information'}
              model="use_external_link"
              checked={edit_information.use_external_link}
              label="Yes"
              labelBefore
            />
          </div>
        </div>
      </div>
    );

    const metaToggle = (
      <div className="md-cell md-cell--12 hf-healdine-margin">
        <h2>
          Do you want this to be an "invisible" card?
        </h2>
        <p>
          This card will not appear in the guidebooks, but will only show up in API requests.
          This is useful as a way to add meta-information about your property and guidebook that can be used by AI.
        </p>
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--1 md-cell--1-phone">
            <p className="md-text md-text-right hf-switch-left">No</p>
          </div>
          <div className="md-cell md-cell--1 md-cell--1-phone">
            <HfSwitchForm
              name="meta_card"
              editModel={'edit_information'}
              model="meta_card"
              checked={edit_information.meta_card}
              label="Yes"
              labelBefore
            />
          </div>
        </div>
      </div>
    )

    const languageForm = edit_information.meta_card ? null : (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">
          {this.formatMessage(hostUIMessages['languageHeading'])}
        </h2>
        <p className="md-body-1">
          {this.formatMessage(hostUIMessages['languageSubHeading'])}
        </p>
        <LocaleForm editModel="edit_information" />
      </div>
    )

    const templateForm = edit_information.meta_card ? null : (
      <div
        id="card-templates-tabs"
        className="md-cell md-cell--12 hf-headline-margin"
      >
        <h2 className="md-headline">
          Choose a template for your card
        </h2>
        <p className="md-body-1">
          This determines what you'll be able to add to the front of
          the card
        </p>
        <TabsContainer
          onTabChange={this.handleTabChange}
          activeTabIndex={template}
          panelClassName="md-grid"
        >
          <Tabs
            tabId="templatesTab"
            className="hfTabs md-cell md-cell--12"
            mobile={true}
          >
            {tabsOutput}
          </Tabs>
        </TabsContainer>
      </div>
    )

    const markLinkRequired = edit_information.use_external_link ? ' *' : ''
    const linkPrompt = edit_information.use_external_link ? (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">
          What link would you like this card to open?
        </h2>
        <p className="md-body-1">
          This will open in a new tab when your guests click this card.
        </p>
        <InputTextField
          model=".external_link"
          id="external_link"
          label={`External Link${markLinkRequired}`}
        />
        {formErrors['external_link']}
      </div>
    ) : null

    const markDescRequired = edit_information.use_external_link ? '' : ' *'
    const descriptionPrompt = edit_information.use_external_link ? null : (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">
          What do you want to tell your guests about this topic?
        </h2>
        <p className="md-body-1">
          You can upload images and format text using the toolbar
          {markDescRequired}
        </p>
        <MultilangWysiwyg
          model="edit_information"
          field="content"
          txn_field="content_txn"
          locales={locales}
          availableVariables={availableVariables}
        />
        {formErrors['content']}
      </div>
    )

    const linkIsValid = (val) => {
      if (val) {
        if (
          val.substring(0, 7) !== 'http://' &&
          val.substring(0, 8) !== 'https://'
        ) {
          return false
        }
        if (val.indexOf('.') === -1) {
          return false
        }
      }
      return true
    }

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      name: {
        required: (val) => val && val.length
      },
      content: {
        required: (val) =>
          requiredIf(
            !edit_information.use_external_link,
            val && fieldHasContent(val)
          )
      },
      external_link: {
        required: (val) =>
          requiredIf(edit_information.use_external_link, val && val.length),
        valid: (val) => linkIsValid(val)
      },
      title: {
        required: (val) => val && val.length,
        length: (val) => val && val.length < 140
      },
      image: {
        required: (val) => (template === 0 ? val && val.length : true)
      },
      video: {
        required: (val) => (template === 1 ? val && val.length : true),
        valid: (val) =>
          !val ||
          (val.indexOf('http') >= 0 &&
            (val.indexOf('embed') > 0 ||
              val.indexOf('player') > 0 ||
              val.indexOf('video') > 0 ||
              val.indexOf('show') > 0))
      },
      lead_para: {
        required: (val) => (template === 2 ? val && val.length : true),
        length: (val) => (template === 2 ? val && val.length < 250 : true)
      },
      icon: {
        required: (val) => !edit_information.meta_card ? val && val.length: true
      }
    }

    return (
      <DocumentTitle title="House Manual">
        <div className="hf-edit-container">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-informations "
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_information"
              onSubmit={(v) => this.handleSubmit(v)}
              onSubmitFailed={() => {
                self.addAnother = false
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField
                    model=".name"
                    id="name"
                    placeholder="My-house-rules-card"
                    label="Card name *"
                  />
                  {formErrors['name']}
                </div>
                {metaToggle}
                {languageForm}
                {templateForm}
                {this.renderFields(formErrors)}
                {linkToggle}
                {linkPrompt}
                {descriptionPrompt}
              </div>
              <div className="md-grid">
                <VoiceForm editModel="edit_information" locales={locales} />
                {restrictAccess}
              </div>
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="informations"
                      ownerSingularName="information"
                      guidebookId={guidebookId}
                      cardType="Information"
                      activeTab={ownerPluralName}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="informations"
                      ownerSingularName="information"
                      guidebookId={guidebookId}
                      cardType="Information"
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-form-errors">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['name']}</div>
                  <div>{formErrors['title']}</div>
                  <div>{formErrors['content']}</div>
                  <div>{formErrors['external_link']}</div>
                  <div>{formErrors['icon']}</div>
                  <div>{formErrors['image']}</div>
                  <div>{formErrors['video']}</div>
                  <div>{formErrors['lead_para']}</div>
                </div>
              </div>
              <FormCardActions saveAndAdd={this.saveAndAddAnother} />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

InformationForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_information = state.edit_information
  const owner_data = state[`edit_${ownerSingularName}`]
  const edit_form = state.forms.edit_information
  const listData = state.list
  return {
    edit_information,
    categories: listData.categories,
    owner_data,
    edit_form
  }
}

export default connect(mapStateToProps)(InformationForm)
