import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { logoutUser } from 'redux/modules/auth'
import { CircularProgress, Paper } from 'react-md'
import NavDrawer from 'components/NavDrawer'
import { push } from 'react-router-redux'

class LoginContainer extends Component {
  componentWillMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.props.dispatch(logoutUser())
    } else {
      this.props.dispatch(push('/login'))
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.isAuthenticated) {
      this.props.dispatch(logoutUser())
    } else {
      this.props.dispatch(push('/login'))
    }
  }

  render() {
    return (
      <NavDrawer showNavDrawer={false}>
        <Paper key="logout" className="hf-auth">
          <div className="md-grid">
            <div className="md-cell md-cell--12 md-text-center hf-md-padding-48">
              {' '}
              Logging out...{' '}
            </div>
            <div className="md-cell md-cell--12 md-text-center hf-md-padding-48">
              <CircularProgress key="progress" id="loader" />
            </div>
            <div className="md-cell md-cell--12 md-text-center hf-md-padding-48"></div>
          </div>
        </Paper>
      </NavDrawer>
    )
  }
}

LoginContainer.propTypes = {
  dispatch: PropTypes.func
}

function mapStateToProps(state, props) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(injectIntl(LoginContainer))
