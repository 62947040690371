import 'whatwg-fetch'

const _resizeFilestackImg = function (image, width, height, crop) {
  // not sure this compression bit is worth it.
  // takes quite a bit longer on initial load, for not a great gain in file
  const compress = false

  const fsKey = process.env.REACT_APP_FILESTACK_KEY
  //https://process.filestackapi.com/AwctNK2zJSRaY1MSHvhF5z/resize=width:40/http://i.imgur.com/RRUe0Mo.png
  const path = 'https://process.filestackapi.com/'.concat(fsKey)
  let resizeTask = '/resize='
  if (width) {
    resizeTask = resizeTask.concat('width:').concat(width)
  }
  if (height) {
    if (width) resizeTask = resizeTask.concat(',')
    resizeTask = resizeTask.concat('height:').concat(height)
  }
  if (width && height && crop) {
    resizeTask = resizeTask.concat(',fit:crop')
  }
  const compressTask = compress ? '/compress' : ''

  return path.concat(resizeTask).concat(compressTask).concat('/').concat(image)
}

// logic for using googleusercontent images dynamic and resizing stuff
const _resizeGoogleImg = function (image, width, height, crop) {
  // first get the pure filename, not the already resized or cropped version
  // s100
  // w760-h540-k [k|s|c|p]
  let baseUrl = image
  let manipConcat = '='
  const regEx = new RegExp('(([/=])([w|h|s][0-9]*)(-h[0-9]*)?(-[c|k|s|p])?/?$)')
  const matches = image.match(regEx)
  if (matches && matches.length > 2) {
    if (matches[2] === '/') {
      manipConcat = '/'
    }
    // const manipString = image.match(regEx);
    baseUrl = image.replace(regEx, '')
  }

  // now set up the new paramaters using our input
  let newParams = manipConcat
  if (width) {
    newParams = newParams.concat('w').concat(width)
  }
  if (height) {
    if (width) newParams = newParams.concat('-')
    newParams = newParams.concat('h').concat(height)
  }
  if (crop) {
    newParams = newParams.concat('-p') // p = smart crop
  }
  if (manipConcat === '/') {
    newParams = newParams.concat('/')
  }

  return baseUrl.concat(newParams)
}

export const resizeImage = function (image, width, height, crop) {
  // make sure we have what we need
  if (!image || (!width && !height)) return image

  // if the image is already a google image (and many are), just use their native resize/recrop tools
  if (image.indexOf('googleusercontent.com') !== -1) {
    return _resizeGoogleImg(image, width, height, crop)
  }
  // otherwise, use the filestack api to resize and crop
  return _resizeFilestackImg(image, width, height, crop)
}

export const preloadImages = function (imageUrl, sizes) {
  if (imageUrl && sizes && sizes.length) {
    for (var i = 0; i < sizes.length; i++) {
      const url = resizeImage(imageUrl, sizes[i][0], sizes[i][1], true)
      const options = {
        method: 'GET'
      }
      // just fetch each size asynchronously and do nothing with response
      fetch(url, options).then((response) => {
        // do nothing (for now)
      })
    }
  }
}
