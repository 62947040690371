import { actions } from 'react-redux-form'
import { push, replace } from 'react-router-redux'

import { getToken } from 'utils/Auth'

import blankEditStates from './blankEditStates'
import { fetchItems } from './list'

import { addToast } from '../toast'

export const IN_FLIGHT = 'IN_FLIGHT'
export const AUTO_SAVE = 'AUTO_SAVE'
export const GET_SUCCESS = 'GET_SUCCESS'
export const API_ERROR = 'API_ERROR'

/* Reducers */
export default function buildReducer(initialState) {
  return function reducer(state = initialState, action = {}) {
    const { type, name } = action
    const data = {}
    switch (type) {
      case API_ERROR:
        data[name] = Object.assign({}, state[name], {
          hasErrored: action.hasErrored,
          errors: action.errors
        })
        return Object.assign({}, state, data)
      case IN_FLIGHT:
        data[name] = Object.assign({}, state[name], {
          isLoading: action.isLoading
        })
        return Object.assign({}, state, data)
      case AUTO_SAVE:
        data[name] = Object.assign({}, state[name], {
          autoSaving: action.autoSaving
        })
        return Object.assign({}, state, data)
      case GET_SUCCESS:
        // BA don't need to save the item as it's stored in the edit_[item] in react-redux-form
        data[name] = Object.assign({}, state[name], {
          isLoading: false,
          data: action.data
        })
        var res = Object.assign({}, state, data)
        return res
      //return Object.assign({}, state, {data : action.item});
      default:
        return state
    }
  }
}

/* Actions */
export function apiError(bool, singularName, errors) {
  return {
    type: API_ERROR,
    name: singularName,
    hasErrored: bool,
    errors: errors
  }
}
export function notFound() {
  return (dispatch) => {
    // if we use replace here we don't need the history.go(-2) stuff
    dispatch(replace('/host/404'))
  }
}
export function inFlight(bool, singularName) {
  return {
    type: IN_FLIGHT,
    name: singularName,
    isLoading: bool
  }
}
export function autoSaving(bool, singularName) {
  return {
    type: AUTO_SAVE,
    name: singularName,
    autoSaving: bool
  }
}
export function saveSuccess(
  singularName,
  pluralName,
  itemId,
  savedItem,
  addAnother,
  currentGuidebook,
  keepEditing
) {
  return (dispatch) => {
    let url

    const cardType = singularName === 'hostintro' ? 'host_intro' : singularName

    let blankEditState = blankEditStates[singularName].data
    if (currentGuidebook) {
      const ownerPluralName = currentGuidebook.is_template
        ? 'templates'
        : 'guidebooks'
      const ownerSingularName = currentGuidebook.is_template
        ? 'template'
        : 'guidebook'
      const currentGuidebookId = currentGuidebook.id || 'create' // if it's a new gb it won't have an id so must be creating
      const selected =
        savedItem[ownerPluralName] &&
        savedItem[ownerPluralName].find(function (gb) {
          return gb.id === currentGuidebook.id
        })
      const gbSelected = currentGuidebookId === 'create' || selected
      // based on whether they've selected the guidebook for this card, update the parent guidebook state, so when they return to it, it all looks right
      if (pluralName === 'recommendations' || pluralName === 'informations') {
        if (itemId === 'create') {
          if (gbSelected) {
            // add this to their list of cards
            const newItems = [...currentGuidebook[pluralName]]
            newItems.push(savedItem)
            dispatch(
              actions.change(
                `edit_${ownerSingularName}.${pluralName}`,
                newItems
              )
            )
          }
        } else {
          // existing, check if it's already selected
          const alreadySelected = currentGuidebook[pluralName].find(
            function (item) {
              return item.id === savedItem.id
            }
          )
          if (gbSelected) {
            if (!alreadySelected) {
              // add to the list
              const newItems = [...currentGuidebook[pluralName]]
              newItems.unshift(savedItem)
              dispatch(
                actions.change(
                  `edit_${ownerSingularName}.${pluralName}`,
                  newItems
                )
              )
            }
          } else {
            // make sure it's not in the current list
            const items = [...currentGuidebook[pluralName]]
            const newList = items.filter((item) => {
              return item.id !== savedItem.id
            })
            dispatch(
              actions.change(`edit_${ownerSingularName}.${pluralName}`, newList)
            )
          }
        }
      } else {
        // single item selected
        dispatch(
          actions.change(
            `edit_${ownerSingularName}.${cardType}`,
            gbSelected ? savedItem : {}
          )
        )
      }
      if (addAnother) {
        // preselect the guidebook
        if (currentGuidebookId !== 'create') {
          blankEditState = Object.assign({}, blankEditState, {
            guidebooks: [{ id: currentGuidebookId }]
          })
        }
        url = `/host/${ownerPluralName}/${currentGuidebookId}/${pluralName}/create`
      } else {
        url = `/host/${ownerPluralName}/${currentGuidebookId}#${pluralName}#${singularName}`
      }
    } else {
      if (addAnother) {
        url = `/host/${pluralName}/create`
      } else if (keepEditing) {
        url = `/host/${pluralName}/${itemId}`
      } else {
        url = `/host/${pluralName}`
      }
    }
    dispatch(apiError(false, singularName))
    if (!keepEditing) {
      dispatch(actions.change('edit_' + singularName, blankEditState))
    } else {
      dispatch(actions.change('edit_' + singularName, savedItem))
    }
    dispatch(push(url))
  }
}

export function autoSaveSuccess(pluralName, singularName, savedItem, reload) {
  return (dispatch) => {
    dispatch(addToast('Right, your work has been saved'))
    dispatch(apiError(false, singularName))
    // if we just saved a guidebook with recs_only_enabled for the first time there will be a key
    if (singularName === 'guidebook' && savedItem.recs_only_key) {
      dispatch(
        actions.change(
          'edit_' + singularName + '.recs_only_key',
          savedItem.recs_only_key
        )
      )
    }
    dispatch(actions.change('edit_' + singularName + '.id', savedItem.id))
    if (reload) {
      const url = `/host/${pluralName}/${savedItem.id}`
      dispatch(push(url))
    }
  }
}
export function deleteSuccess(pluralName) {
  return (dispatch) => {
    if (pluralName === 'guidebooks' || pluralName === 'templates') {
      // guidebooks are deleted from the individual edit page, so redirect back to the list
      dispatch(push('/host/' + pluralName))
    } else {
      dispatch(fetchItems(pluralName))
    }
  }
}

function processSaveResponse(response) {
  return new Promise((resolve, reject) => {
    // will resolve or reject based on status, but pass both status and data
    // this way both the "then" and "catch" will have status and data to work with
    const func = response.status < 400 ? resolve : reject
    response.json().then((json) =>
      func({
        status: response.status,
        data: json.data ? json.data : json
      })
    )
  })
}

function processSaveError(response) {
  let errors = {}
  if (response.status === 422) {
    if (response.data && response.data.error) {
      errors['account'] = [response.data.message]
    }
    if (response.data.errors) {
      errors = response.data.errors
    }
  }
  return errors
}

export function autoSaveItem(
  pluralName,
  singularName,
  id,
  item,
  guidebookId,
  successCb
) {
  const path = '/api/v1/' + pluralName + (id ? '/' + id : '')
  const method = id ? 'PUT' : 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = {}
  body[singularName] = item
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  const reloadAfter = typeof id === 'undefined'
  return (dispatch) => {
    dispatch(autoSaving(true, singularName))
    fetch(url, config)
      .then(processSaveResponse)
      .then(function (saveResponse) {
        dispatch(autoSaving(false, singularName))
        // autosave response has an extra nested "data"
        dispatch(
          autoSaveSuccess(
            pluralName,
            singularName,
            saveResponse.data,
            reloadAfter
          )
        )
        if (typeof successCb === 'function') {
          successCb(saveResponse)
        }
      })
      .catch((response) => {
        dispatch(autoSaving(false, singularName))
        const errors = processSaveError(response)
        dispatch(actions.setSubmitFailed('edit_' + singularName))
        dispatch(apiError(true, singularName, errors))
      })
  }
}

export function saveItem(
  pluralName,
  singularName,
  itemId,
  item,
  addAnother,
  currentGuidebook,
  successCb,
  keepEditing
) {
  const id = itemId === 'create' ? null : itemId
  const path = '/api/v1/' + pluralName + (id ? '/' + id : '')
  const method = id ? 'PUT' : 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = {}
  body[singularName] = item
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return (dispatch) => {
    dispatch(inFlight(true, singularName))
    fetch(url, config)
      .then(processSaveResponse)
      .then(function (saveResponse) {
        dispatch(inFlight(false, singularName))
        dispatch(
          saveSuccess(
            singularName,
            pluralName,
            itemId,
            saveResponse.data,
            addAnother,
            currentGuidebook,
            keepEditing
          )
        )
        if (typeof successCb === 'function') {
          successCb(saveResponse)
        }
      })
      .catch((response) => {
        dispatch(inFlight(false, singularName))
        const errors = processSaveError(response)
        dispatch(actions.setSubmitFailed('edit_' + singularName))
        dispatch(apiError(true, singularName, errors))
      })
  }
}

export function deleteItem(pluralName, singularName, id) {
  const path = '/api/v1/' + pluralName + '/' + id
  const method = 'DELETE'
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return (dispatch) => {
    dispatch(inFlight(true, singularName))
    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            return response.json().then(function (body) {
              const err = body.errors
              // set a toast with the details
              const msg = err.guidebooks
                ? 'It is being used by a guidebook or template'
                : 'Unknown error'
              dispatch(addToast('Unable to delete item: ' + msg))
            })
          } else {
            throw Error(response.statusText)
          }
        }
        dispatch(inFlight(false, singularName))
        dispatch(deleteSuccess(pluralName))
        return response
      })
      .catch(function (err) {
        dispatch(apiError(true, singularName))
      })
  }
}

export function fetchSuccess(singularName, item) {
  // BA  - this uses the react-redux-form action to populate the data in the specific editor.
  return {
    type: GET_SUCCESS,
    name: singularName,
    data: item
  }
}

export function newItem(singularName) {
  return (dispatch) => {
    const blankEditState = blankEditStates[singularName]
    if (singularName === 'guidebook' || singularName === 'template') {
      // only for guidebooks, reset to a blank edit state.
      // This is because we can edit cards, then return to the GuidebookForm
      // and we don't want it to reset the form in that case.
      dispatch(actions.load('edit_' + singularName, blankEditState.data))
    }
    dispatch(fetchSuccess(singularName, blankEditState.data))
  }
}

export function editItem(pluralName, id) {
  return (dispatch) => {
    const editUrl = '/host/' + pluralName + '/' + id
    dispatch(push(editUrl))
  }
}

export function editItemAndAttach(pluralName, id, additional_id) {
  return (dispatch) => {
    const editUrl = '/host/' + pluralName + '/' + id + '/' + additional_id
    dispatch(push(editUrl))
  }
}

export function copyItem(pluralName, id) {
  return (dispatch) => {
    dispatch(push('/host/' + pluralName + '/' + id + '/copy'))
  }
}

export function createTemplateFromGuidebook(id) {
  // fetch the guidebook data
  const url = process.env.REACT_APP_API_URL + '/api/v1/guidebooks/' + id
  const token = getToken()
  const singularName = 'template'
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return (dispatch) => {
    dispatch(inFlight(true, singularName))
    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404 || response.status === 400) {
            dispatch(notFound())
          }
          throw Error(response.statusText)
        }
        dispatch(inFlight(false, singularName))
        return response
      })
      .then(function (response) {
        return response.json()
      })
      .then(function (itemResponse) {
        let item = itemResponse.data
        // reset the id so it creates a new one on save.
        delete item.id
        delete item.name
        delete item.template
        delete item.key
        delete item.address.id
        delete item.recs_only_key
        dispatch(push('/host/templates/create'))
        dispatch(actions.load('edit_template', item))
      })
  }
}

export function newGuidebookWithTemplate(templateId) {
  return (dispatch) => {
    const newGb = blankEditStates['guidebook']
    // newGb is immutable, so let's do a clone
    const assignableData = Object.assign({}, newGb.data)
    // templateId  can be either the id itself, or an object {templateId: <id>}
    let templateData = {}
    if (templateId) {
      if (templateId.templateId) {
        templateData = { id: templateId.templateId }
      } else {
        templateData = { id: templateId }
      }
    }
    assignableData.template = templateData
    dispatch(actions.load('edit_guidebook', assignableData))
    dispatch(push('/host/guidebooks/create'))
  }
}

/* Actions */
export function fetchItem(pluralName, singularName, id, copyFunction, cb) {
  let base = pluralName
  if (pluralName === 'bookingsyncrentals') {
    base = 'bookingsync/rentals'
  }
  if (pluralName === 'orbirentalproperties') {
    base = 'orbirental/properties'
  }
  const url = process.env.REACT_APP_API_URL + '/api/v1/' + base + '/' + id
  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return (dispatch) => {
    dispatch(inFlight(true, singularName))
    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404 || response.status === 400) {
            dispatch(notFound())
          }
          throw Error(response.statusText)
        }
        dispatch(inFlight(false, singularName))
        return response
      })
      .then(function (response) {
        return response.json()
      })
      .then(function (itemResponse) {
        let item = itemResponse.data
        if (copyFunction) {
          item = copyFunction(item)
        }
        dispatch(actions.load('edit_' + singularName, item))
        if (cb) {
          cb(itemResponse.data)
        }
        //dispatch(fetchSuccess(singularName, item));
        return itemResponse.data
      })
      .catch(function (err) {
        dispatch(apiError(true, singularName, err))
      })
  }
}
