import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { FontIcon, Paper, Switch, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import HfSwitchForm from 'components/HfSwitchForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import { hostUIMessages } from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'
import { fieldHasContent, requiredIf } from 'utils/ValidationHelpers'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class WifiForm extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#wifis#wifi'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  formatMessage = this.props.intl.formatMessage

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.wifi.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_wifi', newItem))
    }
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId

    const { ...mutable } = item
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem('wifis', 'wifi', id, mutable, addAnother, currentGuidebook)
    )
  }

  handleWifiSecure = (value) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_wifi.wifi_secure', value))
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    for (var field in formErrors) {
      if (edit_form[field] && !edit_form[field].valid) {
        return false
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_wifi'))
  }

  wifiTypes = [
    'wifi_provided',
    'online',
    'offline',
    'if_issues_reset',
    'if_issues_cafe',
    'if_issues_alt',
    'wifi_not_provided',
    'use_external_cafe',
    'use_external_network'
  ]

  renderFields = (formErrors) => {
    const { edit_wifi, checkBoxData } = this.props
    const locales = edit_wifi.locales
    const user = currentUser()
    const fields = this.wifiTypes
      .filter((wifiType) => {
        // filter out any where the checkbox isn't checked
        return checkBoxData[wifiType]
      })
      .map((wifiType) => {
        let fieldsArray = []
        if (wifiType === 'wifi_provided') {
          fieldsArray.push(
            <div
              key="field_wifi_access"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                How do you want to tell guests the wifi password?
              </h2>
              <CheckBoxForm
                model=".login_provided_online"
                label="Include it on this card"
              />
              <CheckBoxForm
                model=".login_provided_offline"
                label="List it at the property"
              />
            </div>
          )
        }
        if (wifiType === 'wifi_provided' && checkBoxData['online']) {
          fieldsArray.push(
            <div
              key="field_online_dependents"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                What wifi network name and password should guests use?
              </h2>
              <div className="md-grid md-grid--no-spacing">
                <div className="md-cell md-cell--6">
                  <InputTextField
                    model=".network_name"
                    id="network_name"
                    label="Network name *"
                  />
                  {formErrors['network_name']}
                </div>
                <div className="md-cell md-cell--6">
                  <InputTextField
                    model=".wifi_password"
                    id="wifi_password"
                    label="Wifi password *"
                  />
                  {formErrors['wifi_password']}
                </div>
              </div>
            </div>
          )
          if (user.canUseSecurity) {
            fieldsArray.push(
              <div
                key="field_wifi_secure"
                className="md-cell md-cell--12 hf-headline-margin"
              >
                <h2 className="md-headline">
                  Do you want to restrict access to the wifi details?
                </h2>
                <p className="md-body-1">
                  This will make the network details only available to guests
                  viewing your guidebook with a secure link. (This is not
                  necessary if your are restricing access to your entire
                  guidebook using secure links.)&nbsp;
                  <a
                    className="hf-grey-link"
                    target="_blank"
                    href="/host/tokens"
                  >
                    You can create and edit secure links here
                  </a>
                </p>
                <div className="md-grid md-grid--no-spacing">
                  <div className="md-cell md-cell--1 md-cell--1-phone">
                    <p className="md-text md-text-right hf-switch-left">No</p>
                  </div>
                  <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                    <Switch
                      id="checkin_has_secure_info"
                      name="checkin_has_secure_info"
                      checked={edit_wifi.wifi_secure}
                      onChange={this.handleWifiSecure}
                      label="Yes"
                    />
                  </div>
                </div>
              </div>
            )
          }
        }
        if (wifiType === 'wifi_provided' && checkBoxData['offline']) {
          fieldsArray.push(
            <div
              key="field_login_offline_text"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                Explain where to find the wifi details
                <span className="md-body-1"> *</span>
              </h2>
              <MultilangWysiwyg
                model="edit_wifi"
                field="login_offline_text"
                txn_field="login_offline_text_txn"
                locales={locales}
              />
              {formErrors['login_offline_text']}
            </div>
          )
        }
        if (wifiType === 'wifi_provided') {
          fieldsArray.push(
            <div
              key="field_wifi_reset_options"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                Select the statements which best describe how you would like
                guests to resolve wifi issues:
              </h2>
              <CheckBoxForm
                model=".if_issues_reset"
                label="Reset the wifi themselves"
              />
              <CheckBoxForm
                model=".if_issues_cafe"
                label="Use a local cafe with wifi"
              />
              <CheckBoxForm
                model=".if_issues_call"
                label="Contact their host"
              />
            </div>
          )
        }
        if (wifiType === 'wifi_provided' && checkBoxData['if_issues_reset']) {
          fieldsArray.push(
            <div
              key="field_reset"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                Explain how to reset the wifi router?
                <span className="md-body-1"> *</span>
              </h2>
              <MultilangWysiwyg
                model="edit_wifi"
                field="reset_text"
                txn_field="reset_text_txn"
                locales={locales}
              />
              {formErrors['reset_text']}
            </div>
          )
        }
        if (wifiType === 'wifi_not_provided') {
          fieldsArray.push(
            <div
              key={'field_no_wifi_options'}
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                Select the statements which describe good options for accessing
                wifi nearby:
              </h2>
              <CheckBoxForm
                model=".use_external_cafe"
                label="Wifi is available at a nearby cafe"
              />
              <CheckBoxForm
                model=".use_external_network"
                label="Guests can access an external network"
              />
              <CheckBoxForm
                model=".no_wifi_available"
                label="There is no wifi available"
              />
            </div>
          )
        }

        if (
          (wifiType === 'wifi_provided' && checkBoxData['if_issues_cafe']) ||
          (wifiType === 'wifi_not_provided' &&
            checkBoxData['use_external_cafe'])
        ) {
          fieldsArray.push(
            <div
              key="field_cafe_address"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                What is the name of the local cafe that has wifi?
              </h2>
              <InputTextField
                model=".cafe_address"
                id="cafe_address"
                label="Nearby Wifi Cafe Name *"
              />
              {formErrors['cafe_address']}
            </div>
          )
        }
        if (
          wifiType === 'wifi_not_provided' &&
          checkBoxData['use_external_network']
        ) {
          fieldsArray.push(
            <div
              key="field_external_network_name"
              className="md-cell md-cell--12 hf-headline-margin"
            >
              <h2 className="md-headline">
                What is the name of the external network your guests could use?
              </h2>
              <InputTextField
                model=".external_network_name"
                id="external_network_name"
                label="External network name *"
              />
              {formErrors['external_network_name']}
            </div>
          )
        }
        // console.log( fieldsArray );
        return <div key={'optional_fields_' + wifiType}>{fieldsArray}</div>
      })

    // add the custom information field regardless of checkboxes
    fields.push(
      <div
        key="field_wifi_rules"
        className="md-cell md-cell--12 hf-headline-margin"
      >
        <h2 className="md-headline">
          Is there anything else you need to tell guests about using the wifi?
        </h2>
        <MultilangWysiwyg
          model="edit_wifi"
          field="wifi_rules_text"
          txn_field="wifi_rules_text_txn"
          locales={locales}
        />
      </div>
    )

    return fields
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_wifi'))
  }

  render() {
    const self = this
    const {
      itemId,
      edit_wifi,
      checkBoxData,
      copy,
      guidebookId,
      ownerPluralName
    } = this.props
    const title = copy
      ? 'Copy wifi card'
      : itemId === 'create'
        ? 'New wifi card'
        : 'Edit wifi card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      label: {
        required: 'Please provide a label'
      },
      network_name: {
        required:
          'Please enter the network name or deselect "include it on this card" above'
      },
      external_network_name: {
        required:
          'Please enter details here or deselect "Guests can access an external network" above'
      },
      cafe_address: {
        required:
          'Please enter cafe details here or deselect options related to using a cafe above'
      },
      reset_text: {
        required:
          'Please enter instructions here or deselect "Reset the wifi themselves" above'
      },
      login_offline_text: {
        required:
          'Please enter instructions here or deselect "List it at the property" above'
      },
      wifi_password: {
        required:
          'Please enter the password or deselect "include it on this card" above'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_wifi"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['network_name'] = (
      <Errors
        model=".network_name"
        messages={errorMessages.network_name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['external_network_name'] = (
      <Errors
        model=".external_network_name"
        messages={errorMessages.external_network_name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['cafe_address'] = (
      <Errors
        model=".cafe_address"
        messages={errorMessages.cafe_address}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['reset_text'] = (
      <Errors
        model=".reset_text"
        messages={errorMessages.reset_text}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['login_offline_text'] = (
      <Errors
        model=".login_offline_text"
        messages={errorMessages.login_offline_text}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['wifi_password'] = (
      <Errors
        model=".wifi_password"
        messages={errorMessages.wifi_password}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    const user = currentUser()
    if (edit_wifi.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_wifi.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      label: {
        required: (val) => val && val.length
      },
      network_name: {
        // if checkBoxData['wifi_provided'] is true and checkBoxData['online']
        required: (val) =>
          requiredIf(
            checkBoxData['wifi_provided'] && checkBoxData['online'],
            val && val.length
          )
      },
      wifi_password: {
        required: (val) =>
          requiredIf(
            checkBoxData['wifi_provided'] && checkBoxData['online'],
            val && val.length
          )
      },
      login_offline_text: {
        required: (val) =>
          requiredIf(
            checkBoxData['wifi_provided'] && checkBoxData['offline'],
            fieldHasContent(val)
          )
      },
      reset_text: {
        required: (val) =>
          requiredIf(
            checkBoxData['wifi_provided'] && checkBoxData['if_issues_reset'],
            fieldHasContent(val)
          )
      },
      cafe_address: {
        required: (val) =>
          requiredIf(
            checkBoxData['if_issues_cafe'] || checkBoxData['use_external_cafe'],
            fieldHasContent(val)
          )
      },
      external_network_name: {
        required: (val) =>
          requiredIf(checkBoxData['use_external_network'], fieldHasContent(val))
      }
    }

    return (
      <DocumentTitle title="Wifi">
        <div className="hf-wifis-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-wifis"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_wifi"
              onSubmit={(v) => this.handleSubmit(v)}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField
                    model=".label"
                    id="label"
                    label="Card name *"
                    placeholder="My-wifi-card"
                  />
                  {formErrors['label']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_wifi" />
                </div>

                <div
                  key="wifi_availability"
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">
                    Is wifi provided inside the property?
                  </h2>
                  <div className="md-grid md-grid--no-spacing">
                    <div className="md-cell md-cell--1 md-cell--1-phone">
                      <p className="md-text md-text-right hf-switch-left">No</p>
                    </div>
                    <div className="md-cell md-cell--1 md-cell--1-phone">
                      <HfSwitchForm
                        name="wifi_provided"
                        editModel="edit_wifi"
                        model="wifi_provided"
                        checked={checkBoxData['wifi_provided']}
                        label="Yes"
                        labelBefore
                      />
                    </div>
                  </div>
                </div>

                {this.renderFields(formErrors)}
              </div>
              <VoiceForm editModel="edit_wifi" locales={edit_wifi.locales} />
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="wifis"
                      ownerSingularName="wifi"
                      guidebookId={guidebookId}
                      cardType="Wifi"
                      activeTab={ownerPluralName}
                      replaceWarning={true}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="wifis"
                      ownerSingularName="wifi"
                      guidebookId={guidebookId}
                      cardType="Wifi"
                      replaceWarning={true}
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['label']}</div>
                  <div>{formErrors['network_name']}</div>
                  <div>{formErrors['wifi_password']}</div>
                  <div>{formErrors['external_network_name']}</div>
                  <div>{formErrors['reset_text']}</div>
                  <div>{formErrors['cafe_address']}</div>
                  <div>{formErrors['login_offline_text']}</div>
                  <div>{formErrors['host_intro']}</div>
                </div>
              </div>
              <FormCardActions
                guidebookId={guidebookId}
                saveAndAdd={this.saveAndAddAnother}
              />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

WifiForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

// todo reduce like this
function getCheckBoxData(data) {
  return {
    wifi_provided: data.wifi_provided,
    wifi_not_provided: !data.wifi_provided,
    online: data.login_provided_online,
    offline: data.login_provided_offline,
    if_issues_reset: data.if_issues_reset,
    if_issues_cafe: data.if_issues_cafe,
    if_issues_alt: data.if_issues_alt,
    use_external_cafe: data.use_external_cafe,
    use_external_network: data.use_external_network
  }
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_wifi = state.edit_wifi
  const owner_data = state[`edit_${ownerSingularName}`]
  const checkBoxData = getCheckBoxData(state.edit_wifi)
  const edit_form = state.forms.edit_wifi
  return {
    edit_wifi,
    owner_data,
    checkBoxData,
    edit_form
  }
}

export default connect(mapStateToProps)(WifiForm)
