import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Button } from 'react-md'

import SharePanel from '../../SharePanel'

const propTypes = {
  gbKey: PropTypes.string,
  isPublished: PropTypes.bool,
  domain: PropTypes.string
}

const defaultProps = {
  isPublished: false,
  domain: null
}

class GuidebookStatus extends PureComponent {
  handleUnpublish = () => {
    this.props.onUnpublish()
  }

  render() {
    const { gbKey, isPublished, domain, locales } = this.props

    return isPublished ? (
      <div className="hf-gb-status">
        <div className="published">PUBLISHED</div>
        <SharePanel
          domain={domain}
          isPublished={isPublished}
          gbKey={gbKey}
          locales={locales}
        />
        <Button
          className="hf-unpublish-btn"
          onClick={this.handleUnpublish}
          raised
          secondary
        >
          UNPUBLISH
        </Button>
      </div>
    ) : (
      <div className="hf-gb-status">
        <div className="draft">UNPUBLISHED</div>
      </div>
    )
  }
}

GuidebookStatus.propTypes = propTypes
GuidebookStatus.defaultProps = defaultProps

export default GuidebookStatus
