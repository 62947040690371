import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, CircularProgress, DialogContainer, FontIcon } from 'react-md'
import { connect } from 'react-redux'
import { Errors, Form } from 'react-redux-form'

import AddressForm from 'components/AddressForm'
import CheckBoxForm from 'components/CheckBoxForm'
import InputTextField from 'components/InputTextField'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TimePickerForm from 'components/TimePickerForm'
import { wizardSteps } from 'constants/UIConstants'
import { fieldHasContent } from 'utils/ValidationHelpers'

import WizardProgress from './WizardProgress'

const propTypes = {
  address: PropTypes.object,
  closeWizard: PropTypes.func.isRequired,
  editItem: PropTypes.object,
  editChildren: PropTypes.object,
  image: PropTypes.string,
  generateRecsAndNext: PropTypes.func.isRequired,
  previewGuidebook: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  saveAndCloseWizard: PropTypes.func.isRequired,
  saveInitialAndReloadWizard: PropTypes.func.isRequired,
  singularName: PropTypes.string,
  skipStep: PropTypes.func.isRequired,
  toggleUseWizard: PropTypes.func.isRequired,
  wizardStep: PropTypes.number,
  working: PropTypes.bool
}

const defaultProps = {
  address: {},
  image: null,
  singularName: 'guidebook',
  wizardStep: 0
}

class GuidebookWizard extends Component {
  renderWizardStep = (step) => {
    let content = null
    let title = wizardSteps[step].title
    let stepName = wizardSteps[step].stepName
    let buttons = []
    let staticData = {}
    // this.childChanged = false;

    const { address, image, singularName } = this.props
    const editName = 'edit_' + singularName
    let validators = null
    const formErrors = []
    // some default buttons
    // first step is not skippable
    const skipButton = (
      <Button
        key="btn_skip"
        onClick={this.props.skipStep}
        iconChildren="skip_next"
        className="wizard-button md-text--theme-tertiary"
        flat
      >
        skip this step
      </Button>
    )
    const submitAndNextButton = (
      <Button
        type="submit"
        key="btn_next"
        iconChildren="navigate_next"
        className="wizard-button"
        raised
        primary
      >
        Save & Next
      </Button>
    )
    const submitAndCloseButton = (
      <Button
        type="submit"
        key="btn_saveclose"
        iconChildren="save"
        className="wizard-button md-text--theme-tertiary"
        flat
      >
        Save & Close
      </Button>
    )
    const saveInitialButton = (
      <Button
        key="btn_saveandnext"
        onClick={this.props.saveInitialAndReloadWizard}
        iconChildren="navigate_next"
        className="wizard-button"
        raised
        primary
      >
        Save & Next
      </Button>
    )
    const closeButton = (
      <Button
        key="btn_closewizard"
        onClick={this.props.closeWizard}
        iconChildren="cancel"
        className="wizard-button md-text--theme-tertiary"
        flat
      >
        Close Wizard
      </Button>
    )

    const loading = this.props.autoSaving && (
      <CircularProgress key="progress" scale={1} />
    )

    // set default buttons we use most of the time.
    // can be overwritten below
    buttons =
      step === wizardSteps.length
        ? [submitAndCloseButton, closeButton]
        : [closeButton, skipButton, submitAndNextButton]
    switch (stepName) {
      case 'choice':
        buttons = [
          <Button
            key="btn_buildfromscratch"
            onClick={() => {
              this.props.toggleUseWizard(false)
            }}
            iconChildren="list"
            className="wizard-button md-text--theme-tertiary"
            flat
          >
            Build from Scratch
          </Button>,
          <Button
            key="btn_usewizard"
            onClick={() => {
              this.props.toggleUseWizard(true)
            }}
            iconChildren="flare"
            className="wizard-button"
            raised
            primary
          >
            Use Wizard
          </Button>
        ]
        content = (
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--12 wizard-section">
              <div className="wizard-heading">
                The wizard is the fastest way to <strong>start</strong> building
                a guidebook.
              </div>
              <p>
                Select{' '}
                <strong className="md-text--theme-primary">USE WIZARD</strong>{' '}
                below to guide you through a series of 10 simple questions. Each
                step in this process will build a new part of your guidebook.
              </p>
              <p>
                Choose{' '}
                <strong className="md-text--theme-tertiary">
                  BUILD FROM SCRATCH
                </strong>{' '}
                if you wish to start from scratch and add information manually
                to each section.
              </p>
              <p></p>
            </div>
            <div className="md-cell md-cell--12 wizard-info-notice">
              <FontIcon>info</FontIcon> Once you're done here, you should go in
              and fill out each section more completely.
            </div>
            <div className="md-cell md-cell--12 wizard-buttons">{buttons}</div>
          </div>
        )
        break
      case 'location':
        buttons =
          address.lat && address.lng
            ? [submitAndCloseButton, saveInitialButton]
            : [closeButton]
        content = (
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--12 wizard-section">
              <AddressForm
                editModel={editName}
                address={address}
                image={image}
                minimalist={true}
                prompt="Enter location"
              />
            </div>
            <div className="md-cell md-cell--12 wizard-section">
              Please enter the street address for your property, and we'll bring
              in information from Google maps to help you get started.
            </div>
            <div className="md-cell md-cell--12 wizard-info-notice">
              <FontIcon className="md-text--theme-primary">info</FontIcon> You
              can change the listing address later by editing the guidebook.
            </div>
            <div className="md-cell md-cell--12 wizard-buttons">
              {buttons}
              {loading}
            </div>
          </div>
        )

        break
      case 'recsstart':
        const onStartGenClick = () => {
          this.props.generateRecsAndNext()
        }
        buttons = [
          <Button
            key="btn_skip"
            onClick={this.props.skipStep}
            iconChildren="skip_next"
            className="wizard-button md-text--theme-tertiary"
            flat
          >
            skip this step
          </Button>,
          <Button
            key="btn_recswizarrd"
            onClick={onStartGenClick}
            iconChildren="flare"
            className="wizard-button"
            raised
            primary
          >
            Generate Recommendations
          </Button>
        ]
        content = (
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--12 wizard-section">
              <div className="wizard-heading">
                Would you like to automatically generate local recommendations?
              </div>
              <p>
                This will generate recommendations for your guidebook in the
                most commonly used categories: Places to Eat, Bars, Shopping,
                Attractions, Grocery Stores, and Activities.
              </p>
              <p>
                This can take some time, so we'll get started building them now
                and revisit this later.
              </p>
            </div>
            <div className="md-cell md-cell--12 wizard-info-notice">
              <FontIcon className="md-text--theme-primary">info</FontIcon> You
              should review all the recommendations and either edit the copy to
              suit your needs or delete them altogether, as you see fit. You can
              also create your own recommendations afterwards, if you have a
              favorite business that is not included.
            </div>
            <div className="md-cell md-cell--12 wizard-buttons">{buttons}</div>
          </div>
        )
        break
      case 'checkin':
        content = (
          <Form model="edit_checkin" onSubmit={(v) => this.props.nextStep(v)}>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  When is the earliest time guests can check in?
                </div>
                <TimePickerForm
                  editModel="edit_checkin"
                  model="checkin_time"
                  id="checkin_time"
                  defaultValue="2:00 PM"
                  value={this.props.editChildren.checkin.checkin_time}
                  label="Check-in time *"
                />
              </div>
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit your answer later and add more detailed check-in
                instructions in the Check-in card in the Arrival section.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'checkout':
        content = (
          <Form model="edit_checkout" onSubmit={(v) => this.props.nextStep(v)}>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  What is the latest time guests can check out?
                </div>
                <TimePickerForm
                  editModel="edit_checkout"
                  model="checkout_time"
                  id="checkout_time"
                  defaultValue="10:00 AM"
                  value={this.props.editChildren.checkout.checkout_time}
                  label="Checkout time *"
                />
              </div>
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit your answer and add more detailed checkout instructions
                later on your checkout card.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'wifi':
        validators = {
          network_name: {
            // if checkBoxData['wifi_provided'] is true and checkBoxData['online']
            required: (val) => val && val.length
          },
          wifi_password: {
            required: (val) => val && val.length
          }
        }

        formErrors['network_name'] = (
          <Errors
            model=".network_name"
            messages={{ required: 'Please enter the network name' }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        formErrors['wifi_password'] = (
          <Errors
            model=".wifi_password"
            messages={{ required: 'Please enter the password' }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        staticData = { wifi_provided: true, login_provided_online: true }
        content = (
          <Form
            model="edit_wifi"
            onSubmit={(v) => this.props.nextStep(v, staticData)}
            validators={validators}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  What wifi name and password should guests use?
                </div>
                <div className="md-grid">
                  <div className="md-cell md-cell--6">
                    <InputTextField
                      model=".network_name"
                      id="network_name"
                      label="Network name *"
                    />
                    {formErrors['network_name']}
                  </div>
                  <div className="md-cell md-cell--6">
                    <InputTextField
                      model=".wifi_password"
                      id="wifi_password"
                      label="Wifi password *"
                    />
                    {formErrors['wifi_password']}
                  </div>
                </div>
              </div>
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit this information later in the Wifi card and add more
                details about the wifi network and rules.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'intro':
        // Host Intro
        validators = {
          host_name: {
            required: (val) => val && val.length
          },
          host_intro: {
            // if checkBoxData['wifi_provided'] is true and checkBoxData['online']
            required: (val) => val && fieldHasContent(val)
          }
        }

        formErrors['host_intro'] = (
          <Errors
            model=".host_intro"
            messages={{ required: 'Please enter your welcome message' }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        formErrors['host_name'] = (
          <Errors
            model=".host_name"
            messages={{ required: 'Please enter your name' }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        content = (
          <Form
            model="edit_hostintro"
            onSubmit={(v) => this.props.nextStep(v)}
            validators={validators}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  What is the name of the guests' main contact?
                </div>
                <InputTextField
                  model=".host_name"
                  id="host_name"
                  label="Host name *"
                />
                {formErrors['host_name']}
              </div>
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  Enter a welcome message for guests
                </div>
                <p>
                  We've included an example here to get you started. Please edit
                  or add to this message by clicking in the text box below.
                </p>
                <MultilangWysiwyg
                  model="edit_hostintro"
                  field="host_intro"
                  txn_field="host_intro_txn"
                  minimalTools
                  locales={[]}
                />
                {formErrors['host_intro']}
              </div>
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit your answer later and add more detail in the host
                introduction card.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'directions':
        // const {edit_children} = this.props;
        validators = {
          additional_directions: {
            required: (val) => val && fieldHasContent(val)
          }
        }

        formErrors['additional_directions'] = (
          <Errors
            model=".additional_directions"
            messages={{ required: 'Please enter some directions' }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        content = (
          <Form
            model="edit_directions"
            onSubmit={(v) => this.props.nextStep(v)}
            validators={validators}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  What is the best way to get to the listing?
                </div>
                <p className="md-body-1">
                  Most people will use GPS these days, but this is a good place
                  to enter specific details about how to get to your listing.
                </p>
                <MultilangWysiwyg
                  model="edit_directions"
                  field="additional_directions"
                  txn_field="additional_directions_txn"
                  placeholder="Any notable landmarks nearby? Something that needs to be done to access the driveway? Any routes to avoid that GPS would use?"
                  minimalTools
                  locales={[]}
                />
              </div>
              {formErrors['additional_directions']}
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit your answer later in the directions card, and add
                specific directions from notable locations, like the nearst
                airport or train station.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'parking':
        const atLeastOneChecked = (vals) => {
          if (
            vals &&
            (vals.driveway_available ||
              vals.on_street_available ||
              vals.economical_available ||
              vals.expensive_nearby)
          ) {
            return true
          }
          return false
        }

        validators = {
          '': {
            formHasValues: (vals) => atLeastOneChecked(vals)
          }
        }

        formErrors['formwide'] = (
          <Errors
            model="edit_parking"
            messages={{
              formHasValues: 'Please check at least one box, or skip'
            }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        content = (
          <Form
            model="edit_parking"
            onSubmit={(v) => this.props.nextStep(v)}
            validators={validators}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  Please select from these standard parking instructions:
                </div>
                <CheckBoxForm
                  model=".driveway_available"
                  label="Driveway parking available"
                  className="hf-taller-selection-control-container hf-wider-selection-control-container"
                />
                <CheckBoxForm
                  model=".on_street_available"
                  label="On-Street parking available"
                  className="hf-taller-selection-control-container hf-wider-selection-control-container"
                />
                <CheckBoxForm
                  model=".economical_available"
                  label="Economical parking available"
                  className="hf-taller-selection-control-container hf-wider-selection-control-container"
                />
                <CheckBoxForm
                  model=".expensive_nearby"
                  label="Parking is expensive nearby"
                  className="hf-taller-selection-control-container hf-wider-selection-control-container"
                />
              </div>
              {formErrors['formwide']}
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit this info later and add more details by editing your
                parking card.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'rules':
        // house rules
        validators = {
          content: {
            // if checkBoxData['wifi_provided'] is true and checkBoxData['online']
            required: (val) => val && fieldHasContent(val)
          }
        }

        formErrors['content'] = (
          <Errors
            model=".content"
            messages={{ required: 'Please enter some house rules' }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        staticData = {
          title: title,
          template: 0,
          image: 'https://cdn.filestackcontent.com/OPyB20a2Tsaagt7xyx66',
          icon: 'LibraryBooks'
        }
        content = (
          <Form
            model="edit_information"
            onSubmit={(v) => this.props.nextStep(v, staticData, null, true)}
            validators={validators}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  To get the ball rolling, here is a standard set of house
                  rules.
                </div>
                <p>
                  Please edit or add to these rules by clicking in the text box
                  below.
                </p>
                <MultilangWysiwyg
                  model="edit_information"
                  field="content"
                  txn_field="content_txn"
                  minimalTools
                  locales={[]}
                />
                {formErrors['content']}
              </div>
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit this info later and add more cards in the house manual
                section of your guidebook form.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break

      case 'emergency':
        // Emergency Contact Numbers
        validators = {
          content: {
            // if checkBoxData['wifi_provided'] is true and checkBoxData['online']
            required: (val) => val && fieldHasContent(val)
          }
        }

        formErrors['content'] = (
          <Errors
            model=".content"
            messages={{
              required: 'Please enter some emergency contact information'
            }}
            wrapper="h4"
            className="md-text-field-message title hf-error"
            show={(form) => form.submitFailed}
          />
        )
        staticData = {
          title: title,
          template: 0,
          image: 'https://cdn.filestackcontent.com/ESZsZ3IzRbGaOOzYtDy2',
          icon: 'Health'
        }
        content = (
          <Form
            model="edit_information"
            onSubmit={(v) => this.props.nextStep(v, staticData)}
            validators={validators}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 wizard-section">
                <div className="wizard-heading">
                  Please provide at least one emergency contact number.
                </div>
                <p>Add the information by clicking in the text box below.</p>
                <MultilangWysiwyg
                  model="edit_information"
                  field="content"
                  txn_field="content_txn"
                  minimalTools
                  locales={[]}
                />
                {formErrors['content']}
              </div>
              <div className="md-cell md-cell--12 wizard-info-notice">
                <FontIcon className="md-text--theme-primary">info</FontIcon> You
                can edit this info later and add more cards in the house manual
                section of your guidebook form.
              </div>
              <div className="md-cell md-cell--12 wizard-buttons">
                {buttons}
              </div>
            </div>
          </Form>
        )
        break
      case 'congrats':
        const onPreviewClick = () => {
          this.props.previewGuidebook(true)
        }
        buttons = [
          <Button
            key="btn_closewizard"
            onClick={this.props.closeWizard}
            iconChildren="cancel"
            className="wizard-button md-text--theme-tertiary"
            flat
          >
            No thanks, I'm done.
          </Button>,
          <Button
            key="btn_recswizarrd"
            onClick={onPreviewClick}
            iconChildren="flare"
            className="wizard-button"
            raised
            primary
          >
            Open Preview
          </Button>
        ]
        // TODO when we auto-publish the first guidebook, we'll wanna ditch this
        // detect whether it is still a draft here.
        // we'll also want to change the copy for the info box below
        const isDraft = !this.props.editItem.is_published
        const draftNotice = isDraft ? (
          <strong className="md-text--theme-secondary">draft </strong>
        ) : (
          ''
        )
        const draftInstructions = isDraft
          ? 'You can edit this guidebook at any time and add more information to each section, adjust the selected recommendations, and publish it so you can send it out to guests.'
          : 'You can edit this guidebook at any time and add more information to each section, and adjust the selected recommendations.'
        const publishNotice = isDraft ? (
          <div className="md-cell md-cell--12 wizard-info-notice">
            <FontIcon className="md-text--theme-primary">info</FontIcon> You
            will need to publish this draft once you have previewed it in order
            for guests to be able to view it.
          </div>
        ) : null
        content = (
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--12 wizard-section">
              <div className="wizard-heading">
                Your {draftNotice}guidebook is ready to view!
              </div>
              <p>
                You can click 'Open Preview' below to take a look right now.
              </p>
              <p>{draftInstructions}</p>
            </div>
            {publishNotice}
            <div className="md-cell md-cell--12 wizard-buttons">{buttons}</div>
          </div>
        )
        break
      default:
        break
    }
    return { content, title }
  }

  render = () => {
    const { wizardStep } = this.props
    const { title, content } = this.renderWizardStep(wizardStep)
    // TODO - this should be moved to CSS most likely
    const wizardStyle = { width: '90%', maxWidth: '640px' }
    const showProgress =
      wizardStep > 0 && wizardStep < 9 ? (
        <WizardProgress wizardStep={wizardStep} />
      ) : (
        <h2>{title}</h2>
      )

    return (
      <DialogContainer
        id="gbWizard"
        visible={true}
        onHide={this.props.saveAndCloseWizard}
        aria-label="Guidebook Wizard"
        autosizeContent={true}
        focusOnMount={true}
        portal={true}
        lastChild={true}
        modal={true}
        disableScrollLocking={false}
        dialogStyle={wizardStyle}
        className="hf-wizard-modal"
      >
        <div className="md-grid md-grid--no-spacing wizard-modal">
          <div className="md-cell md-cell--12 wizard-section">
            {showProgress}
          </div>
          <div className="md-cell md-cell--12 wizard-section">{content}</div>
        </div>
      </DialogContainer>
    )
  }
}

GuidebookWizard.propTypes = propTypes
GuidebookWizard.defaultProps = defaultProps

function mapStateToProps(state, props) {
  const { singularName } = props
  const data = state.edit_item[singularName]

  return {
    autoSaving: data.autoSaving
  }
}

export default connect(mapStateToProps)(GuidebookWizard)
