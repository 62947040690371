import React from 'react'
import ReactDOM from 'react-dom'

import { clearAuth, getUser } from 'utils/Auth'

import Main from './Main'

if (window.location !== window.parent.location) {
  window.isIframe = true
  document.getElementById('root').classList.add('hf-external-access')
}

window.addEventListener('message', (event) => {
  const user = getUser()

  // Ensure the message is coming from a trusted source
  if (user && event.origin === process.env.REACT_APP_V3_BASE_URL) {
    switch (event.data.type) {
      case 'userAuth':
        if (event.data.userEmail !== user.email) {
          clearAuth()
          window.parent.postMessage(
            { type: 'wrongUser', userEmail: event.data.userEmail },
            event.origin
          )
          window.location.href = '/login'
        }

        break
      default:
        return
    }
  }
})

ReactDOM.render(<Main />, document.getElementById('root'))
