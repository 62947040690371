import { sortData } from 'utils/Data'

export const updatePotentialTabs = (guidebook) => {
  if (guidebook.key === 'loading') {
    return {}
  }

  const categoryHasItems = (category, type) => {
    let useCategory = category.toLowerCase().replace(/ /g, '_')
    if (type === 'recommendations') {
      return (
        guidebook &&
        guidebook.recommendationsByCategory &&
        guidebook.recommendationsByCategory[useCategory] &&
        guidebook.recommendationsByCategory[useCategory].recommendations &&
        guidebook.recommendationsByCategory[useCategory].recommendations
          .length > 0
      )
    } else {
      return (
        guidebook &&
        guidebook.informationsByCategory &&
        guidebook.informationsByCategory[useCategory] &&
        guidebook.informationsByCategory[useCategory].informations &&
        guidebook.informationsByCategory[useCategory].informations.length > 0
      )
    }
  }

  const removeTabs = (whichTab) => {
    const guidebooksToInclude = ['glltkdz', 'ggwrcmq', 'HarveyHelp', 'IrmaHelp']
    if (guidebooksToInclude.indexOf(guidebook.key) !== -1) {
      return false
    }
    if (
      whichTab === 'arrival' &&
      guidebook.theme &&
      guidebook.theme.hide_arrival
    ) {
      return false
    } else if (
      whichTab === 'departure' &&
      guidebook.theme &&
      guidebook.theme.hide_departure
    ) {
      return false
    } else if (
      whichTab === 'ai' &&
      guidebook.theme &&
      !guidebook.theme.show_itinerary_builder
    ) {
      return false
    } else if (
      whichTab === 'all' &&
      guidebook.theme &&
      guidebook.theme.hide_all
    ) {
      return false
    }
    return true
  }

  // take our categories and sort by order
  const recCategories = []
  Object.keys(guidebook.recommendationsByCategory).forEach(function (key) {
    recCategories.push(guidebook.recommendationsByCategory[key].category)
  })
  const infoCategories = []
  Object.keys(guidebook.informationsByCategory).forEach(function (key) {
    if (key !== 'house_manual') {
      infoCategories.push(guidebook.informationsByCategory[key].category)
    }
  })

  const allCategories = recCategories.concat(infoCategories)
  const sortedCategories = sortData(allCategories, 'order')

  let allTabs = {
    arrival: {
      type: 'essentials',
      present: removeTabs('arrival'),
      icon: 'VpnKey',
      color: '#f44336'
    },
    house_manual: {
      type: 'essentials',
      present: hasDefaultHouseManualTab(guidebook),
      icon: 'Book',
      color: '#ff5722'
    },
    departure: {
      type: 'essentials',
      present: removeTabs('departure'),
      icon: 'Assignment',
      color: '#ff9800'
    },
    ai: {
      type: 'essentials',
      present: removeTabs('ai'),
      icon: 'Power',
      color: '#74aa9c'
    },
    marketplace: {
      type: 'essentials',
      present:
        guidebook &&
        guidebook.marketplace_items &&
        guidebook.marketplace_items.length,
      icon: 'ShoppingCart',
      color: '#ffc107'
    }
  }

  // now loop through and add to the object
  for (var i = 0; i < sortedCategories.length; i++) {
    if (sortedCategories[i]) {
      let cat_object = sortedCategories[i]
      if (sortedCategories[i].type === 'house manual') {
        cat_object['type'] = 'house manual'
      } else {
        cat_object['type'] = 'recommendations'
      }
      let cat_key = sortedCategories[i].name.toLowerCase().replace(/ /g, '_')
      // remove ALL if set in theme
      if (cat_key === 'all' && sortedCategories[i].id === 0) {
        cat_object['present'] = removeTabs('all')
          ? categoryHasItems(cat_object.name.toLowerCase(), cat_object.type)
          : false
      } else {
        cat_object['present'] = categoryHasItems(
          cat_object.name.toLowerCase(),
          cat_object.type
        )
      }
      allTabs[cat_key] = cat_object
    }
  }
  // okie dokie.  if we have a theme and we have an altered tab order,
  // loop thorugh once more and put them in order
  if (
    guidebook.theme &&
    guidebook.theme.tab_orders &&
    Object.keys(guidebook.theme.tab_orders).length
  ) {
    const { tab_orders } = guidebook.theme
    let reorderedTabs = []
    const sortedTabs = {}
    for (const key of Object.keys(allTabs)) {
      const order = tab_orders.hasOwnProperty(key) ? tab_orders[key] : 999
      let tab = allTabs[key]
      tab.order = order
      tab.key = key
      reorderedTabs.push(tab)
    }
    reorderedTabs = sortData(reorderedTabs, 'order')
    for (const tab of reorderedTabs) {
      sortedTabs[tab.key] = tab
    }
    allTabs = sortedTabs
  }
  return allTabs
}

const hasDefaultHouseManualTab = (guidebook) => {
  if (guidebook.informations.length) {
    const defaultItems = guidebook.informations.filter((item) => {
      return item.category === null
    })
    if (defaultItems.length) {
      return true
    }
  }
  return false
}

export const getAvailableTabs = (potentialTabs, recsOnly) => {
  // console.log(potentialTabs);
  const availableTabs = []
  for (var tabKey in potentialTabs) {
    if (potentialTabs[tabKey].present) {
      if (!recsOnly || potentialTabs[tabKey].type === 'recommendations') {
        availableTabs.push(tabKey)
      }
    }
  }
  return availableTabs
}

export const isEssentialsTab = (key, guidebook) => {
  if (Object.keys(guidebook.recommendationsByCategory).indexOf(key) !== -1) {
    return false
  }
  return true
}
