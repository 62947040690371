import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { debounce } from 'utils/Debounce'
import { camelize } from 'utils/Strings'

import GoogleApiWrapper from './GoogleApiWrapper'

const evtNames = ['ready', 'click', 'dragend', 'bounds_changed', 'idle']

const propTypes = {
  google: PropTypes.object,
  zoom: PropTypes.number,
  lat: PropTypes.number,
  lng: PropTypes.number,
  fitType: PropTypes.string,
  onMove: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  inIdle: PropTypes.func,
  onWindowResized: PropTypes.func,
  onTilesLoaded: PropTypes.func,
  onVisibleMarkersChange: PropTypes.func,
  useLocation: PropTypes.bool
}

const defaultProps = {
  zoom: 13,
  // San Francisco, by default
  lat: 37.774929,
  lng: -122.419416,
  onMove: function () {},
  onWindowResized: function () {},
  fitType: 'smart',
  useLocation: false
}

class Map extends Component {
  constructor(props) {
    super(props)

    this.fit = false
    this.locationLoaded = false
    this.state = {
      map: null,
      markers: null,
      useLocation: props.useLocation,
      currentLocation: null
    }

    this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 250)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.state.markers) {
      this.setState({ markers: nextProps.children })
    }

    if (nextProps.google && nextProps.google !== this.props.google) {
      const map = this.buildMap(nextProps.google)

      this.setState({ map })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentLocation !== this.state.currentLocation) {
      this.recenterMap()
    }

    if (
      prevProps.lat !== this.props.lat ||
      prevProps.lng !== this.props.lng ||
      prevProps.zoom !== this.props.zoom ||
      prevProps.children !== this.props.children
    ) {
      if (this.props.fitType) {
        this.recenterMap()
      }
    }
  }

  async componentDidMount() {
    this.setState({ currentLocation: await this.getCurrentPosition() })
    // listen to window resize (debounced) and add a handler
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  getCurrentPosition = async () => {
    return await new Promise((resolve, reject) => {
      if (this.props.useLocation && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            resolve({
              lat: coords.latitude,
              lng: coords.longitude
            })
          },
          (error) => {
            reject(null)
          }
        )
      } else {
        resolve(null)
      }
    })
  }

  handleWindowResize = (event) => {
    // call the parent onWindowResized,
    // we may also want to do something else here, like recenter
    // either before or after
    this.recenterMap()
    this.props.onWindowResized()
  }

  handleEvent = (evtName) => {
    const self = this
    let timeout
    const handlerName = `on${camelize(evtName)}`

    return (e) => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      timeout = setTimeout(() => {
        if (this.props[handlerName]) {
          self.props[handlerName](this.props, this.state.map, e)
        }
      }, 150)
    }
  }

  recenterMap = () => {
    const { map } = this.state
    const { google, fitType } = this.props
    if (!google) return

    const maps = google.maps
    // trigger a resize to make sure the map knows what size it is
    maps.event.trigger(map, 'resize')
    if (map) {
      if (fitType === 'smart') {
        this.fitMap()
      } else if (fitType === 'bounds') {
        const center = new maps.LatLng(this.props.lat, this.props.lng)

        map.panTo(center)
        map.setZoom(this.props.zoom)
        this.locationLoaded && this.fitMap()
      } else if (fitType === 'center-only') {
        const center = new maps.LatLng(this.props.lat, this.props.lng)
        map.panTo(center)
      }
    }
  }

  getVisibleMarkers = () => {
    const { map, markers } = this.state
    // now track which markers are visible on the map
    const bounds = map.getBounds()

    const visibleMarkers = markers.reduce((arr, marker) => {
      if (bounds.contains(marker.props.position)) {
        arr.push(marker.props.id)
      }
      return arr
    }, [])
    return visibleMarkers
  }

  fitMap = () => {
    const map = this.state.map
    const google = this.props.google

    if (!map || !google) return

    const bounds = new google.maps.LatLngBounds()

    if (this.state.currentLocation) {
      bounds.extend(
        new google.maps.LatLng(
          this.state.currentLocation.lat,
          this.state.currentLocation.lng
        )
      )
    }

    if (this.state.markers.length === 1) {
      let center = new google.maps.LatLng(this.props.lat, this.props.lng)
      map.panTo(center)
    } else if (this.state.markers.length > 1) {
      React.Children.forEach(this.state.markers, (c) => {
        const cp = new google.maps.LatLng(
          c.props.position.lat,
          c.props.position.lng
        )
        bounds.extend(cp)
      })
      map.fitBounds(bounds)
    }
  }

  buildMap = (google) => {
    if (google) {
      // google is available
      const maps = google.maps

      //const mapRef = this.refs.map;
      //use ref callback to get handle to map DOM element
      const node = this[this.props.mapId]

      let { zoom, onTilesLoaded, onVisibleMarkersChange } = this.props
      const { lat, lng } = this.props
      const center = new maps.LatLng(lat, lng)
      var myStyles = [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        },
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#ebe3cd'
            }
          ]
        },
        {
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#EFEBE9'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#A1887F'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#EFEBE9'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#EFEBE9'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#EFEBE9'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#BCAAA4'
            }
          ]
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFF8E1'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dfd2ae'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#d2e5c7'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFFFFF'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFE0B2'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFCC80'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#FFCC80'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.icon',
          stylers: [
            {
              saturation: -90
            },
            {
              lightness: 40
            },
            {
              visibility: 'simplified'
            },
            {
              weight: 2.5
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFCC80'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#FFCC80'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#BCAAA4'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFCCBC'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#BCAAA4'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#EFEBE9'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFCCBC'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#B2EBF2'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#BCAAA4'
            }
          ]
        }
      ]
      this.controlPosition = this.props.className.includes('map-container-full')
        ? google.maps.ControlPosition.RIGHT
        : google.maps.ControlPosition.LEFT_BOTTOM

      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
          zoomControlOptions: {
            position: this.controlPosition
          },
          streetViewControlOptions: {
            position: this.controlPosition
          },
          gestureHandling: 'auto',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT
          },
          styles: myStyles
        }
      )

      const map = new maps.Map(node, mapConfig)

      evtNames.forEach((e) => {
        map.addListener(e, this.handleEvent(e))
      })
      if (onVisibleMarkersChange) {
        // add a listener to track visibleMarkers
        map.addListener('idle', (e) => {
          const visibleMarkers = this.getVisibleMarkers()
          onVisibleMarkersChange(visibleMarkers)
        })
      }

      if (onTilesLoaded) {
        maps.event.addListenerOnce(map, 'tilesloaded', function () {
          onTilesLoaded(true)
        })
      }

      //trigger the ready event
      maps.event.trigger(map, 'ready')
      return map
    }
  }

  renderLocationControls = async () => {
    const location = await this.getCurrentPosition()

    if (this.props.useLocation && !this.locationLoaded && location) {
      const { google } = this.props
      const controlDiv = document.createElement('div')
      const firstChild = document.createElement('button')
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

      firstChild.style.backgroundColor = '#fff'
      firstChild.style.border = 'none'
      firstChild.style.outline = 'none'
      firstChild.style.width = '40px'
      firstChild.style.height = '40px'
      firstChild.style.borderRadius = '2px'
      firstChild.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
      firstChild.style.cursor = 'pointer'
      firstChild.style.marginLeft = '10px'
      firstChild.style.marginRight = '10px'
      firstChild.style.padding = '0px'

      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      svg.setAttribute('height', '28px')
      svg.setAttribute('viewBox', '0 0 24 24')
      svg.setAttribute('width', '28px')
      svg.setAttribute('fill', '#4285F4')

      const path1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      )
      path1.setAttribute('d', 'M0 0h24v24H0V0z')
      path1.setAttribute('fill', 'none')

      const path2 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      )
      path2.setAttribute(
        'd',
        `M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 
        3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 
        11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 
        7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 
        3.13 7 7-3.13 7-7 7z`
      )

      svg.appendChild(path1)
      svg.appendChild(path2)

      firstChild.appendChild(svg)
      controlDiv.appendChild(firstChild)

      this.state.map.controls[this.controlPosition].push(controlDiv)

      const LocationMarker = new google.maps.Marker({
        icon: {
          fillColor: '#4285F4',
          fillOpacity: 1,
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          strokeColor: '#fff',
          strokeWeight: 2
        },
        position: location,
        map: this.state.map
      })

      controlDiv.addEventListener('click', async () => {
        if (this.state.useLocation) {
          svg.setAttribute('fill', '#E8EAEd')
          LocationMarker.setMap(null)
          this.setState({
            useLocation: false,
            currentLocation: null
          })
        } else {
          this.setState({
            useLocation: true,
            currentLocation: await this.getCurrentPosition()
          })
          svg.setAttribute('fill', '#4285F4')
          LocationMarker.setMap(this.state.map)
        }
      })

      this.locationLoaded = true
    }
  }

  renderMarkers = () => {
    const { google } = this.props
    const children = this.state.markers

    if (!children || !this.state.map) return

    const markers = React.Children.map(children, (c) => {
      const child = React.cloneElement(c, {
        map: this.state.map,
        google: google
      })
      return child
    })

    this.renderLocationControls()

    if (!this.fit && markers.length && this.props.fitType) {
      this.recenterMap()
      this.fit = true
    }

    return markers
  }

  render() {
    const markers = this.renderMarkers()

    return (
      <div
        ref={(div) => {
          this[this.props.mapId] = div
        }}
        id={this.props.mapId}
        className={this.props.className}
      >
        {markers}
      </div>
    )
  }
}

Map.propTypes = propTypes
Map.defaultProps = defaultProps

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_GUEST
})(Map)
