import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'components/SvgIcon'
import PrintHeader from 'components/Print/PrintHeader'
import ListingName from 'components/Print/SubSections/ListingName'
import HostIntro from 'components/Print/SubSections/HostIntro'
import { markerColors, categoryIconSvg } from 'constants/UIConstants'
import { resizeImage } from 'utils/Image'

class CoverPage2 extends Component {
  render() {
    const icons = []
    for (var icon in categoryIconSvg) {
      if ({}.hasOwnProperty.call(categoryIconSvg, icon)) {
        icons.push(
          <td key={'icon_' + icon} className="hf-col-sm">
            <SvgIcon
              fillColor={markerColors[icon]}
              size="50px"
              viewBox="0 0 200 200"
            >
              {categoryIconSvg[icon]}
            </SvgIcon>
          </td>
        )
      }
    }

    //remove unwanted icons
    icons.splice(9, 1)

    const iconTable = (
      <table key="cp2-table" className="hf-icon-table">
        <tbody width="100%">
          <tr width="100%">{icons}</tr>
        </tbody>
      </table>
    )

    const coverBackground = (
      <div key="cp2-bg">
        <img
          src="https://storage.googleapis.com/hostfully-wp-1/coverpage2-bg.jpg"
          className="hf-cp2-background"
          alt=""
        />
      </div>
    )

    const listimgImage = resizeImage(this.props.guidebook.image, 300, 300, true)
    const propertyImage = (
      <div className="hf-cp2-property">
        <img src={listimgImage} className="hf-cp2-property" alt="" />
      </div>
    )

    return (
      <div id="coverpage2" className="page-break-after">
        <PrintHeader
          theme={this.props.theme}
          coverPageClass="print-header-cp2"
          alignChildren="center"
        >
          <ListingName
            guidebook={this.props.guidebook}
            styles={this.props.styles ? this.props.styles : null}
          />
        </PrintHeader>
        {iconTable}
        {coverBackground}
        {propertyImage}
        <div className="hf-gb-url">
          http://hostful.ly/{this.props.guidebook.key}
        </div>

        <HostIntro
          guidebook={this.props.guidebook}
          styles={this.props.styles ? this.props.styles : null}
        />
      </div>
    )
  }
}

CoverPage2.propTypes = {
  guidebook: PropTypes.object,
  printPreferences: PropTypes.object,
  onReady: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  styles: PropTypes.object,
  theme: PropTypes.object
}

export default CoverPage2
