import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { Checkbox, FontIcon, Paper, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import { directionsMessages, hostUIMessages } from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'
import { fieldHasContent } from 'utils/ValidationHelpers'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class DirectionsForm extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    // this._preview = this._preview.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#directions#directions'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  formatMessage = this.props.intl.formatMessage

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.directions.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_directions', newItem))
    }
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId

    // train fields are actually saved as 'subway' in the model,
    // but then returned as 'train' in the view
    const { ...mutable } = item
    mutable.show_subway = mutable.show_train
    mutable.subway_text = mutable.train_text
    mutable.subway_text_txn = mutable.train_text_txn

    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem(
        'directions',
        'directions',
        id,
        mutable,
        addAnother,
        currentGuidebook
      )
    )
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    for (var field in formErrors) {
      if (edit_form[field] && !edit_form[field].valid) {
        return false
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_directions'))
  }

  directionsTypes = [
    'airport',
    'taxi',
    'uber',
    'lyft',
    'bus',
    'train',
    'ferry',
    'pickup',
    'driving'
  ]

  handleSelectAll = (checked) => {
    // if checked is true, set all the checkbox types to true
    // if false, set all to false
    const { dispatch } = this.props
    for (let dirType of this.directionsTypes) {
      let id = 'edit_directions.show_' + dirType
      dispatch(actions.change(id, checked))
    }
  }

  getShowAllChecked = () => {
    // if any of the checkboxes are not checked, return false
    const d = this.props.checkBoxData
    for (let dirType of this.directionsTypes) {
      if (!d['show_' + dirType]) return false
    }
    return true
  }

  checkedFieldHasContent = (val, field) => {
    const d = this.props.checkBoxData
    return fieldHasContent(val) || !d['show_' + field]
  }

  formHasValues = (vals) => {
    if (vals.additional_directions) {
      // empty states include a blank string and "<p></p>"
      if (fieldHasContent(vals.additional_directions)) return true
    }
    // check each of the direction types for content
    for (let dirType of this.directionsTypes) {
      if (vals['show_' + dirType] && fieldHasContent(vals[dirType + '_text'])) {
        return true
      }
    }
    return false
  }

  renderCheckboxes = (formErrors) => {
    // add a CheckBoxForm for each of the directionsTypes
    const directionsOptions = this.directionsTypes.map((dirType) => {
      return (
        <div key={dirType} className="md-cell md-cell--4 md-cell--2-phone">
          <CheckBoxForm
            model={'.show_' + dirType}
            name={dirType}
            id={dirType}
            label={this.formatMessage(directionsMessages[dirType])}
            className="hf-wider-selection-control-container"
          />
        </div>
      )
    })

    return (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">
          {this.formatMessage(directionsMessages.directionsPrompt)}
        </h2>
        <p className="md-body-1">
          Each of these items will be presented under the heading "Recommended
          approaches"
        </p>
        {formErrors['edit_directions']}
        <div className="md-grid">
          {directionsOptions}
          <div
            className="md-cell md-cell--4  md-cell--2-phone"
            key="select-all"
          >
            <Checkbox
              name="select-all"
              id="select-all"
              label={this.formatMessage(directionsMessages.selectAll)}
              checked={this.getShowAllChecked()}
              onChange={this.handleSelectAll}
              className="hf-wider-selection-control-container"
            />
          </div>
        </div>
      </div>
    )
  }

  renderFields = (formErrors) => {
    const { edit_directions, checkBoxData } = this.props
    const locales = edit_directions.locales
    const fields = this.directionsTypes
      .filter((dirType) => {
        // filter out any where the checkbox isn't checked
        return checkBoxData['show_' + dirType]
      })
      .map((dirType) => {
        let additionalField = null
        let hostFieldLabel =
          ' travelling by ' +
          this.formatMessage(directionsMessages[dirType]).toLowerCase()
        if (
          dirType === 'airport' ||
          dirType === 'driving' ||
          dirType === 'pickup'
        ) {
          hostFieldLabel = this.formatMessage(
            directionsMessages[dirType + 'Host']
          ).toLowerCase()
        }
        if (dirType === 'uber') {
          //don't convert to lower case
          additionalField = (
            <div>
              <br />
              <h3>
                {this.formatMessage(directionsMessages['uberReferralPrompt'])}
              </h3>
              <p>
                {this.formatMessage(directionsMessages['uberReferralDesc'])}
                <br />
                {this.formatMessage(directionsMessages['uberReferralDesc2'])}
              </p>
              <InputTextField
                model=".uberpool_voucher"
                id="uberpool_voucher"
                fullWidth={false}
              />
            </div>
          )
        } else if (dirType === 'lyft') {
          //don't convert to lower case
          additionalField = (
            <div>
              <br />
              <h3>
                {this.formatMessage(directionsMessages['lyftReferralPrompt'])}
              </h3>
              <p>
                {this.formatMessage(directionsMessages['lyftReferralDesc'])}
                <br />
                {this.formatMessage(directionsMessages['lyftReferralDesc2'])}
              </p>
              <InputTextField
                model=".lyft_voucher"
                id="lyft_voucher"
                fullWidth={false}
              />
            </div>
          )
        }
        return (
          <div
            key={'field_' + dirType}
            className="md-cell md-cell--12 hf-headline-margin"
          >
            <h2 className="md-headline">
              What directions would you give guests {hostFieldLabel}?
            </h2>
            <MultilangWysiwyg
              model="edit_directions"
              field={dirType + '_text'}
              txn_field={dirType + '_text_txn'}
              locales={locales}
            />
            {formErrors[dirType]}
            {additionalField}
          </div>
        )
      })

    return <div className="md-cell md-cell--12">{fields}</div>
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_directions'))
  }

  render() {
    const self = this
    const { itemId, edit_directions, copy, guidebookId, ownerPluralName } =
      this.props
    const title = copy
      ? 'Copy directions card'
      : itemId === 'create'
        ? 'New directions card'
        : 'Edit directions card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      label: {
        required: 'Please provide a label'
      },
      edit_directions: {
        formHasValues:
          'Please recommend at least one method or enter your own directions in the last field on this page'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_directions"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['edit_directions'] = (
      <Errors
        model="edit_directions"
        messages={errorMessages.edit_directions}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    for (var i = 0; i < this.directionsTypes.length; i++) {
      let dirType = this.directionsTypes[i]
      let errorMsg = {
        checkedFieldHasContent:
          'Please add instructions in the ' +
          dirType +
          ' field above, or deselect the ' +
          dirType +
          ' checkbox'
      }
      formErrors[dirType] = (
        <Errors
          model={'.' + dirType + '_text'}
          messages={errorMsg}
          className="md-text-field-message hf-error"
          show={(field) => field.touched && !field.focus}
        />
      )
    }

    const user = currentUser()
    if (edit_directions.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_directions.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors),
        formHasValues: (vals) => this.formHasValues(vals)
      },
      label: {
        required: (val) => val && val.length
      },
      airport_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'airport')
      },
      taxi_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'taxi')
      },
      uber_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'uber')
      },
      lyft_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'lyft')
      },
      bus_text: {
        checkedFieldHasContent: (val) => this.checkedFieldHasContent(val, 'bus')
      },
      train_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'train')
      },
      pickup_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'pickup')
      },
      driving_text: {
        checkedFieldHasContent: (val) =>
          this.checkedFieldHasContent(val, 'driving')
      }
    }

    return (
      <DocumentTitle title="Directions Card">
        <div className="hf-directions-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-directions"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_directions"
              onSubmit={(v) => this.handleSubmit(v)}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField
                    model=".label"
                    id="label"
                    label="Card name *"
                  />
                  {formErrors['label']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_directions" />
                </div>
                <div
                  key={'field_custom'}
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">
                    Provide general directions to the property
                  </h2>
                  <p className="md-body-1">
                    The guest will see this information at the top of the
                    directions card, directly below the address
                  </p>
                  <MultilangWysiwyg
                    model="edit_directions"
                    field="additional_directions"
                    txn_field="additional_directions_txn"
                    locales={edit_directions.locales}
                  />
                </div>
                <div className="md-cell md-cell--12">
                  {this.renderCheckboxes(formErrors)}
                </div>
                {this.renderFields(formErrors)}
              </div>
              <VoiceForm
                editModel="edit_directions"
                locales={edit_directions.locales}
              />
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="directions"
                      ownerSingularName="directions"
                      guidebookId={guidebookId}
                      cardType="Directions"
                      activeTab={ownerPluralName}
                      replaceWarning={true}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="directions"
                      ownerSingularName="directions"
                      guidebookId={guidebookId}
                      cardType="Directions"
                      replaceWarning={true}
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['label']}</div>
                  <div>{formErrors['edit_directions']}</div>
                  <div>{formErrors['airport']}</div>
                  <div>{formErrors['taxi']}</div>
                  <div>{formErrors['uber']}</div>
                  <div>{formErrors['lyft']}</div>
                  <div>{formErrors['bus']}</div>
                  <div>{formErrors['train']}</div>
                  <div>{formErrors['pickup']}</div>
                  <div>{formErrors['driving']}</div>
                </div>
              </div>
              <FormCardActions
                guidebookId={guidebookId}
                saveAndAdd={this.saveAndAddAnother}
              />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

DirectionsForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

// todo reduce like this
function getCheckBoxData(data) {
  return {
    show_airport: data.show_airport,
    show_taxi: data.show_taxi,
    show_uber: data.show_uber,
    show_lyft: data.show_lyft,
    show_bus: data.show_bus,
    show_train: data.show_train,
    show_ferry: data.show_ferry,
    show_pickup: data.show_pickup,
    show_driving: data.show_driving
  }
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_directions = state.edit_directions
  const owner_data = state[`edit_${ownerSingularName}`]
  const checkBoxData = getCheckBoxData(state.edit_directions)
  const edit_form = state.forms.edit_directions
  return {
    edit_directions,
    owner_data,
    checkBoxData,
    edit_form
  }
}

export default connect(mapStateToProps)(DirectionsForm)
