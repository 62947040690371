import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  Avatar,
  Button,
  DialogContainer,
  ExpansionList,
  ExpansionPanel,
  FontIcon,
  LinearProgress,
  List,
  ListItem,
  MenuButton,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { push } from 'react-router-redux'

import CsvCreator from 'components/react-csv-creator'
import { atMostPhablet } from 'components/ScreenSize'
import TooltipButton from 'components/TooltipButton'
import { filterPageAccess } from 'constants/UIConstants'
import NotInPlan from 'pages/ErrorPages/NotInPlan'
import {
  bulkFetch,
  fetchItems,
  newGuidebookWithTemplate
} from 'redux/modules/crud'
import { fetchStats } from 'redux/modules/stats'
import currentUser from 'utils/CurrentUser'

import HfList from './List'
import TemplatePanel from './TemplatePanel'

class ListContainer extends Component {
  constructor(props) {
    super(props)

    this.addNew = this.addNew.bind(this)
    this.addNewInheritTemplate = this.addNewInheritTemplate.bind(this)
    this.renderUpgradeCTA = this.renderUpgradeCTA.bind(this)
    this.renderUpgradeDialog = this.renderUpgradeDialog.bind(this)
    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.reOrder = this.reOrder.bind(this)
    this.state = {
      showUpgradeAlert: false,
      showHelpDialog: false,
      showReorderDialog: false,
      data: null,
      mounted: false
    }
  }

  componentDidMount() {
    this.fetchData(this.props.pluralName)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pluralName !== nextProps.pluralName) {
      this.fetchData(nextProps.pluralName)
    }
  }

  fetchData = (name) => {
    const { dispatch } = this.props

    if (name === 'guidebooks') {
      dispatch(
        bulkFetch(['guidebooks', 'templates'], this.fetchGuidebookExport)
      )
    } else if (['bookingsyncrentals', 'orbirentalproperties'].includes(name)) {
      dispatch(bulkFetch([name, 'guidebooks']))
    } else {
      dispatch(fetchItems(name))
    }
  }

  renderUpgradeDialog = (message, onClick = null) => {
    const actionOnClick =
      typeof onClick === 'function' ? onClick : this.closeDialog
    return (
      <DialogContainer
        id="upgradeAlert"
        visible={this.state.showUpgradeAlert}
        title="Upgrade"
        onHide={this.closeDialog}
        aria-label="Upgrade to access feature alert"
      >
        <List>
          <ListItem
            key="alert1"
            onClick={actionOnClick}
            primaryText={message}
          />
        </List>
      </DialogContainer>
    )
  }

  closeDialog = () => {
    this.setState({ showUpgradeAlert: false })
  }

  openDialog = () => {
    this.setState({ showUpgradeAlert: true })
  }

  showHelpPage = (helpUrl) => {
    window.open(helpUrl, '_blank', 'noopener, noreferrer')
  }

  showHelp = () => {
    this.setState({ showHelpDialog: true })
  }

  hideHelp = () => {
    this.setState({ showHelpDialog: false })
  }

  reOrder = () => {
    const { dispatch, pluralName } = this.props
    dispatch(push('/host/' + pluralName + '/reorder'))
  }

  addNewInheritTemplate = (templateId) => {
    const { dispatch } = this.props

    if (!this.hasAvailableGuidebook()) {
      this.openDialog()
    } else {
      dispatch(newGuidebookWithTemplate(templateId))
    }
  }

  addNew = (itemType) => {
    const { dispatch, pluralName } = this.props

    if (
      pluralName === 'guidebooks' &&
      !this.hasAvailableGuidebook() &&
      itemType !== 'templates'
    ) {
      this.openDialog()
    } else {
      dispatch(
        push(
          `/host/${typeof itemType === 'string' ? itemType : pluralName}/create`
        )
      )
    }
  }

  hasAvailableGuidebook = () => {
    // free users cannot make drafts, so we need to verify their
    // guidebook count
    const { collection } = this.props
    const user = currentUser()
    // # of current guidebooks
    const listCount = collection.data.length
    if (listCount >= user.guidebookLimit && !user.isPaidHost) {
      return false
    }
    return true
  }

  getAvailableCount = (data) => {
    const user = currentUser()
    if (user.isPaidHost) {
      return data.filter(
        (item) => item.is_template === false && item.is_published === true
      ).length
    } else {
      return data.length
    }
  }

  renderUpgradeCTA = () => {
    const { collection, pluralName } = this.props
    const user = currentUser()
    const listItems = []
    const listCount =
      ['bookingsyncrentals', 'orbirentalproperties'].indexOf(pluralName) !== -1
        ? this.getAvailableCount(this.props.guidebooks.data)
        : this.getAvailableCount(collection.data)

    if (user.guidebookLimit <= 5) {
      if (listCount < user.guidebookLimit) {
        for (var i = listCount; i < user.guidebookLimit; i++) {
          listItems.push(
            <ListItem
              onClick={this.addNew}
              className={'hf-semi-transparent hf-list-item hf-blank-list-item'}
              leftAvatar={
                <Avatar className="hf-blank-list-item" role="presentation">
                  <FontIcon>add</FontIcon>
                </Avatar>
              }
              primaryText="Add another guidebook"
              secondaryText="You can under your current plan"
              key={'gbn_' + i}
            />
          )
        }
      }
      listItems.push(
        <ListItem
          onClick={this.upgrade}
          className={'hf-semi-transparent hf-list-item hf-blank-list-item'}
          leftAvatar={
            <Avatar className="hf-blank-list-item" role="presentation">
              <FontIcon>link</FontIcon>
            </Avatar>
          }
          primaryText="Need more guidebooks?"
          secondaryText="Upgrade your plan to create more"
          key="cta997"
        />
      )
    }
    return listItems.length ? (
      <List className="hf-no-top-padding hf-list-wrapper">{listItems}</List>
    ) : null
  }

  upgrade = () => {
    const user = currentUser()
    if (user.guidebookLimit < 5) {
      window.location.href =
        process.env.REACT_APP_WORDPRESS_URL + '/upgrade-prime'
    } else {
      window.location.href =
        process.env.REACT_APP_WORDPRESS_URL + '/upgrade-enterprise'
    }
  }

  fetchGuidebookExport = async () => {
    const user = currentUser()

    if (user.isAdmin) {
      const response = await fetchStats(
        'customer_completion',
        null,
        null,
        user.id
      )

      this.setState({
        data: response?.generalInfo.length ? response.generalInfo : null,
        mounted: true
      })
    } else {
      this.setState({
        mounted: true
      })
    }
  }

  renderHelpToolbar = () => {
    var action = (
      <Button icon onClick={this.hideHelp}>
        close
      </Button>
    )
    return (
      <Toolbar
        key="helptoolbar"
        actions={action}
        title="How to print your guidebook"
        fixed
      />
    )
  }

  renderGuidebookExport = (type) => {
    if (!this.state.data || this.state.data.length === 0) return null

    const gb_filename = 'gb_export'
    const gb_headers = [
      { id: 'id', display: 'id' },
      { id: 'guidebook_name', display: 'Name' },
      { id: 'url', display: 'URL' },
      { id: 'street_no', display: 'Street Number' },
      { id: 'street_name', display: 'Street Name' },
      { id: 'city', display: 'City' },
      { id: 'country', display: 'Country Code' },
      { id: 'postal_code', display: 'Postal Code' },
      { id: 'checkin_card', display: 'Checkin' },
      { id: 'directions_card', display: 'Directions' },
      { id: 'parking_card', display: 'Parking' },
      { id: 'wifi_card', display: 'Wifi' },
      { id: 'checkout_card', display: 'Checkout' },
      { id: 'host_card', display: 'Host' },
      { id: 'is_published', display: 'is_published' }
    ]
    const gb_data = this.state.data
    const csvDivStyle = { display: 'inline-block' }
    const menuButtonStyle = {
      color: '#232323',
      padding: '2px 10px',
      margin: '2px',
      width: '100%'
    }

    if (type === 'menu') {
      return (
        <div style={csvDivStyle} key="mi-export">
          <CsvCreator
            filename={gb_filename}
            headers={gb_headers}
            rows={gb_data}
          >
            <Button
              flat
              iconBefore={false}
              iconEl={<FontIcon>file_download</FontIcon>}
              style={menuButtonStyle}
            >
              Export
            </Button>
          </CsvCreator>
        </div>
      )
    } else {
      return (
        <div style={csvDivStyle}>
          <CsvCreator
            filename={gb_filename}
            headers={gb_headers}
            rows={gb_data}
          >
            <TooltipButton
              key="export"
              tooltipLabel="Download guidebook list as a CSV"
              tooltipPosition="bottom"
              icon
            >
              <FontIcon>file_download</FontIcon>
            </TooltipButton>
          </CsvCreator>
        </div>
      )
    }
  }

  render() {
    const {
      rowRenderer,
      singularName,
      pluralName,
      title,
      collection,
      templates
    } = this.props

    if (collection.isLoading) {
      return (
        <div className="hf-main-container">
          <Toolbar
            colored
            className={'hf-host-toolbar hf-' + pluralName.toLowerCase()}
            title={title}
          />
          <div
            className={
              'md-grid md-grid--no-spacing hf-host-paper hf-' +
              pluralName +
              '-paper'
            }
          >
            <LinearProgress id={`progress-${pluralName}`} />
          </div>
        </div>
      )
    } else if (
      pluralName === 'guidebooks' &&
      this.state.mounted &&
      !collection.data.length &&
      !templates.data.length
    ) {
      return <Redirect to="/getstarted" />
    }

    const { expandTemplatePanel } = this.state
    const user = currentUser()
    const showList = filterPageAccess(pluralName, user)
    const domainUpgrade =
      process.env.REACT_APP_WORDPRESS_URL + '/upgrade-custom-domain?reloadnonce'

    if (!showList) {
      return (
        <div className="hf-main-container">
          <Toolbar
            colored
            className={'hf-host-toolbar hf-' + pluralName.toLowerCase()}
            title={title}
          />
          {pluralName === 'domains' ? (
            <NotInPlan
              description={
                <p className="hf-subtitle">
                  Custom Domains are included in all enterprise accounts, click
                  the Manage Account button below to see upgrade options.
                  Alternatively, you can <a href={domainUpgrade}>click here</a>{' '}
                  to add this permission as a one-time purchase.
                </p>
              }
            />
          ) : (
            <NotInPlan />
          )}
        </div>
      )
    }

    //Toolbar actions
    const actions = [
      <TooltipButton
        key="addnew"
        onClick={this.addNew}
        tooltipLabel="Add New"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>add</FontIcon>
      </TooltipButton>
    ]
    const menuButtonStyle = {
      color: '#232323',
      padding: '2px 10px',
      margin: '2px',
      width: '100%'
    }
    const menuItems = [
      <Button
        key="mi-addnew"
        iconBefore={false}
        iconEl={<FontIcon>add</FontIcon>}
        style={menuButtonStyle}
        onClick={this.addNew}
      >
        Add New
      </Button>
    ]

    if (pluralName === 'guidebooks') {
      // put together simple guidebook data export
      const gbExportButton = this.renderGuidebookExport('button')
      const gbExportMenuButton = this.renderGuidebookExport('menu')

      if (gbExportButton) {
        actions.unshift(gbExportButton)
      }
      if (gbExportMenuButton) {
        menuItems.push(gbExportMenuButton)
      }
      actions.unshift(
        <TooltipButton
          key="help"
          onClick={() => {
            this.showHelpPage(
              'https://help.hostfully.com/en/collections/113571-guidebook-platform'
            )
          }}
          tooltipLabel="Help"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>help</FontIcon>
        </TooltipButton>
      )
      menuItems.push(
        <Button
          flat
          key="mi-help"
          iconBefore={false}
          iconEl={<FontIcon>help</FontIcon>}
          style={menuButtonStyle}
          onClick={() => {
            this.showHelpPage(
              'https://help.hostfully.com/en/collections/113571-guidebook-platform'
            )
          }}
        >
          Help
        </Button>
      )
    }
    //add upgrade prompts if needed
    const gbLimits =
      this.state.mounted &&
      collection.data.length &&
      pluralName === 'guidebooks'
        ? this.renderUpgradeCTA()
        : null
    const itemCount = collection.data.length
      ? ` (${collection.data.length})`
      : ''
    // if we're viewing the guidebooks page, add the template panel
    let templatePanelorDesc = null

    if (pluralName === 'guidebooks' && user.isEnterprise) {
      templatePanelorDesc = (
        <TemplatePanel
          expandPanel={expandTemplatePanel}
          templates={templates.data}
          newCallBack={(itemType) => {
            this.addNew(itemType)
          }}
          newFromTemplateCallBack={(templateId) => {
            this.addNewInheritTemplate(templateId)
          }}
        />
      )
    } else if (pluralName === 'templates') {
      // otherwise add a brief description of templates.
      templatePanelorDesc = (
        <ExpansionList>
          <ExpansionPanel
            label="About Templates"
            className="hf-about-panel"
            footer={null}
            defaultExpanded
          >
            <p>
              Templates are tools to help you share common information across
              multiple guidebooks.
            </p>
            <p>
              If you have multiple guidebooks that share a lot of common cards
              (recommendations, house manual cards, checkin instruction, etc),
              we recommend that you put all of that common information into a
              template, and then make guidebooks using the template. This way
              for each guidebook you'll only need to add the information or
              cards that is distinct to that particular listing.
            </p>
            <p>
              You can preview a template to see what a guidebook that was
              created from the template would look like before modification, but
              your guests will not be able to view a template directly.
            </p>
          </ExpansionPanel>
        </ExpansionList>
      )
    } else if (pluralName === 'categories') {
      const reorderHelpUrl =
        'https://help.hostfully.com/en/articles/3207699-re-ordering-your-guidebook-tabs'
      const categoryHelpUrl =
        'https://help.hostfully.com/en/articles/2963835-hostfully-guidebooks-create-custom-categories'
      templatePanelorDesc = (
        <ExpansionList>
          <ExpansionPanel
            label="About Categories"
            className="hf-about-panel"
            footer={null}
            defaultExpanded
          >
            <p>
              Here you can view the default set of categories for
              recommendations and house manual cards.
              <br />
              You can also create new custom categories of your own. These
              categories will only show up as tabs in your guidebook if cards
              have been assigned to them.
            </p>
            <p>
              You can change the order of your tabs using a theme form:&nbsp;
              <a
                className="hf-grey-link"
                rel="noopener noreferrer"
                href={reorderHelpUrl}
                target="_blank"
              >
                Click here to see how.
              </a>
            </p>
          </ExpansionPanel>
        </ExpansionList>
      )
      actions.unshift(
        <TooltipButton
          key="help"
          onClick={() => {
            this.showHelpPage(categoryHelpUrl)
          }}
          tooltipLabel="Help"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>help</FontIcon>
        </TooltipButton>
      )
    } else if (pluralName === 'domains') {
      const domainHelpUrl =
        'http://help.hostfully.com/en/articles/4998353-using-your-own-custom-domain-for-guidebooks'
      templatePanelorDesc = (
        <ExpansionList>
          <ExpansionPanel
            label="About Domains"
            className="hf-about-panel"
            footer={null}
            defaultExpanded
          >
            <p>
              Here you can request to use a domain that you already own to serve
              your guidebooks.
              <br />
              Please choose a <strong>subdomian</strong> such as{' '}
              <strong>guide.yourdomain.com</strong> or{' '}
              <strong>welcome.yourdomain.com</strong> or{' '}
              <strong>hello.yourdomain.com</strong>.
            </p>
            <p>
              Notice: This process will take a few coordinated steps, going back
              and forth with the Hostfully Team via automated emails.
              <br />
              Some of these steps will require you to have access to your
              domain's DNS settings and be able to add some records.
              <br />
              We recommend reading the help article on the topic linked below
              before beginning this process.
            </p>
            <p>
              <a
                className="hf-grey-link"
                rel="noopener noreferrer"
                href={domainHelpUrl}
                target="_blank"
              >
                More Information about using custom domains can be found here.
              </a>
            </p>
          </ExpansionPanel>
        </ExpansionList>
      )
      actions.unshift(
        <TooltipButton
          key="help"
          onClick={() => {
            this.showHelpPage(domainHelpUrl)
          }}
          tooltipLabel="Help"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>help</FontIcon>
        </TooltipButton>
      )
    } else if (pluralName === 'tokens') {
      const tokenHelpUrl =
        'https://help.hostfully.com/en/articles/3269280-securing-your-guidebook'
      templatePanelorDesc = (
        <ExpansionList>
          <ExpansionPanel
            label="About Secure Links"
            className="hf-about-panel"
            footer={null}
            defaultExpanded
          >
            <p>
              To increase guidebook security and allow you to put more sensitive
              information in your guidebooks, you can create{' '}
              <strong>secure links</strong> for your guests.
            </p>
            <p>
              There are several ways you can secure your guidebook with these
              links:
            </p>
            <ol>
              <li>
                You can restrict access to{' '}
                <strong>
                  <em>your entire guidebook</em>
                </strong>{' '}
                so only guests with a secure link can view it.
              </li>
              <li>
                You can restrict access to{' '}
                <strong>
                  <em>individual house manual cards</em>
                </strong>{' '}
                so only guests with a secure link can view them.
              </li>
              <li>
                You can restrict access to{' '}
                <strong>
                  <em>select information on certain cards</em>
                </strong>
                , such as the network details on your wifi card or the access
                information on your checkin card, and only guests viewing your
                guidebooks with one of these secure links will be able to view
                the secure information in those cards.
              </li>
            </ol>
            <p>
              <a
                className="hf-grey-link"
                target="_blank"
                href={tokenHelpUrl}
                rel="noopener noreferrer"
              >
                More information about secure links can be found here.
              </a>
            </p>
          </ExpansionPanel>
        </ExpansionList>
      )
      actions.unshift(
        <TooltipButton
          key="help"
          onClick={() => {
            this.showHelpPage(tokenHelpUrl)
          }}
          tooltipLabel="Help"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>help</FontIcon>
        </TooltipButton>
      )
    }

    const useResponsiveMenu =
      atMostPhablet && ['categories', 'guidebooks'].indexOf(pluralName) !== -1
    const responsiveActions = useResponsiveMenu ? (
      <MenuButton id="menu-botton" icon menuItems={menuItems}>
        more_vert
      </MenuButton>
    ) : (
      actions
    )

    return (
      <div className="hf-list-container">
        <Toolbar
          colored
          className={'hf-host-toolbar hf-' + pluralName.toLowerCase()}
          title={title + itemCount}
          actions={responsiveActions}
        />
        <Button
          className={window.isIframe ? '' : 'hf-new-item'}
          onClick={this.addNew}
          tooltipLabel={`Add new ${singularName}`}
          tooltipPosition="left"
          floating
          secondary
          fixed
          mini
        >
          add
        </Button>
        <div className="hf-paper">
          {templatePanelorDesc}
          <List className="hf-list-wrapper">
            <HfList
              pluralName={pluralName}
              rowRenderer={rowRenderer}
              collection={this.props}
              {...this.props}
            />
          </List>
          {gbLimits}
        </div>
        {this.renderUpgradeDialog(
          'You have reached your guidebook limit. Manage your account to add more guidebooks and allow unlimited drafts'
        )}
      </div>
    )
  }
}

ListContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pluralName: PropTypes.string.isRequired,
  singularName: PropTypes.string.isRequired,
  rowRenderer: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const { pluralName } = props
  const listData = state['list']
  const data = listData[pluralName]
  let result

  switch (pluralName) {
    case 'bookingsyncrentals':
    case 'orbirentalproperties':
      result = {
        collection: data,
        guidebooks: listData['guidebooks']
      }
      break
    case 'guidebooks':
      result = {
        collection: data,
        templates: listData['templates']
      }
      break
    default:
      result = {
        collection: data
      }
  }
  return result
}

export default connect(mapStateToProps)(injectIntl(ListContainer))
