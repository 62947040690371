import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { Button, Checkbox, Media, Paper, Switch, Toolbar } from 'react-md'
import FontIcon from 'react-md/lib/FontIcons'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TimePickerForm from 'components/TimePickerForm'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import {
  checkinMessages,
  hostUIMessages,
  prefixFields,
  tokenFields
} from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class CheckinForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      earlyCheckShowText: false
    }
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#checkins#checkin'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_checkin'))
  }

  formatMessage = this.props.intl.formatMessage

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.checkin.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_checkin', newItem))
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    // ugly hack as we don't store a boolean for show_early_checkin_text
    const { edit_checkin } = nextProps
    if (
      edit_checkin.early_checkin_text &&
      edit_checkin.early_checkin_text !== ''
    ) {
      this.setState({ earlyCheckShowText: true })
    }
  }

  removeImage() {
    const { dispatch } = this.props
    dispatch(actions.change('edit_checkin.access_image', null))
  }

  handleSubmit(item) {
    const { itemId, guidebookId, dispatch, copy } = this.props
    const id = copy ? null : itemId

    const { ...mutable } = item

    if (!this.state.earlyCheckShowText) {
      mutable.early_checkin_text = ''
      mutable.early_checkin_text_txn = {}
    }
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem('checkins', 'checkin', id, mutable, addAnother, currentGuidebook)
    )
  }

  handleCheckinHasSecure = (value) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_checkin.checkin_has_secure_info', value))
  }

  handleUseCheckinEndTime = (value) => {
    const { dispatch, edit_checkin } = this.props
    // if our state does not yet have a checkin_end_time, set the default
    if (value === true && edit_checkin.checkin_end_time === '') {
      dispatch(actions.change('edit_checkin.checkin_end_time', '10:00 PM'))
    }
    dispatch(actions.change('edit_checkin.use_checkin_end_time', value))
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    if (edit_form.$form.touched) {
      for (var field in formErrors) {
        if (edit_form[field] && !edit_form[field].valid) {
          return false
        }
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_checkin'))
  }

  onEarlyCheckChange = (checked) => {
    const { dispatch, edit_checkin } = this.props
    this.setState({ earlyCheckShowText: checked })
    if (checked && !edit_checkin.early_checkin_text) {
      dispatch(actions.change('edit_checkin.early_checkin_text', ''))
    }
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_checkin'))
  }

  render() {
    const self = this
    const { edit_checkin, itemId, copy, guidebookId, ownerPluralName } =
      this.props
    const locales = edit_checkin.locales
    const title = copy
      ? 'Copy check-in card'
      : itemId === 'create'
        ? 'New check-in card'
        : 'Edit check-in card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )

    const image = edit_checkin.access_image
    const preview_image = image ? (
      <Media aspectRatio="1-1">
        <img src={image} alt="" />
      </Media>
    ) : (
      <Media aspectRatio="1-1">
        <img
          src="https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png"
          alt=""
        />
      </Media>
    )

    const removeButton = image ? (
      <Button
        className="hf-button-margin"
        onClick={this.removeImage}
        raised
        secondary
      >
        Remove Image
      </Button>
    ) : null

    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      label: {
        required: 'Please provide a name for this card'
      },
      checkin_time: {
        required: 'Please enter a check-in time'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_checkin"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['checkin_time'] = (
      <Errors
        model=".checkin_time"
        messages={errorMessages.checkin_time}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const user = currentUser()
    if (edit_checkin.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_checkin.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }
    const formatMessage = this.props.intl.formatMessage

    const enterEarlyText = this.state.earlyCheckShowText ? (
      <MultilangWysiwyg
        simple
        model="edit_checkin"
        field="early_checkin_text"
        txn_field="early_checkin_text_txn"
        locales={locales}
      />
    ) : null

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      label: {
        required: (val) => val && val.length
      },
      checkin_time: {
        required: (val) => val && val.length
      }
    }

    const availableVariables = user.canUseSecurity
      ? prefixFields(tokenFields, 'token')
      : []
    const accessPrompt =
      user.canUseSecurity && edit_checkin.checkin_has_secure_info
        ? 'Enter your public information here:'
        : 'What information does your guest need to know in order to gain access to the property?'

    return (
      <div>
        <DocumentTitle title="Host Checkin">
          <div className="hf-checkins-paper">
            <Toolbar
              colored
              className="hf-edit-toolbar hf-checkins"
              title={title}
              nav={nav}
              actions={actions}
            />
            <Paper key="category" className="hf-form-wrapper">
              <Form
                model="edit_checkin"
                onSubmit={(v) => this.handleSubmit(v)}
                onSubmitFailed={() => {
                  self.addAnother = false
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    return this.handleEnter(e)
                  }
                }}
                validators={validators}
              >
                <div className="md-grid">
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      {this.formatMessage(hostUIMessages['nameHeading'])}
                    </h2>
                    <InputTextField
                      model=".label"
                      id="label"
                      label="Card name *"
                      placeholder="Property Name"
                      helpText="Use a name that will tell you what guidebooks it is suited to. For example if you can reuse this card on all guidebooks in the same estate then call it Estate ABC. But if the card is specific to a property use the property name."
                      helpOnFocus={true}
                    />
                    {formErrors['label']}
                  </div>
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      {this.formatMessage(hostUIMessages['languageHeading'])}
                    </h2>
                    <p className="md-body-1">
                      {this.formatMessage(hostUIMessages['languageSubHeading'])}
                    </p>
                    <LocaleForm editModel="edit_checkin" />
                  </div>
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      When is the earliest time guests can check in?
                    </h2>
                    <TimePickerForm
                      editModel="edit_checkin"
                      model="checkin_time"
                      id="checkin_time"
                      defaultValue="2:00 PM"
                      value={this.props.edit_checkin.checkin_time}
                      label="Check-in time *"
                    />
                    {formErrors['checkin_time']}
                  </div>
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Do you want a check-in window instead of a single time?
                    </h2>
                    <div className="md-grid md-grid--no-spacing">
                      <div className="md-cell md-cell--1 md-cell--1-phone">
                        <p className="md-text md-text-right hf-switch-left">
                          No
                        </p>
                      </div>
                      <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                        <Switch
                          id="use_checkin_end_time"
                          name="use_checkin_end_time"
                          checked={edit_checkin.use_checkin_end_time}
                          onChange={this.handleUseCheckinEndTime}
                          label="Yes"
                        />
                      </div>
                    </div>
                  </div>
                  {edit_checkin.use_checkin_end_time ? (
                    <div className="md-cell md-cell--12 hf-headline-margin">
                      <h2 className="md-headline">
                        When is the latest time guests can check in?
                      </h2>
                      <TimePickerForm
                        editModel="edit_checkin"
                        model="checkin_end_time"
                        id="checkin_end_time"
                        defaultValue="10:00 PM"
                        value={this.props.edit_checkin.checkin_end_time}
                        label="Check-in end time *"
                      />
                      {formErrors['checkin_end_time']}
                    </div>
                  ) : null}
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Select the statements that best describe your policy on
                      early check-in
                    </h2>
                    <CheckBoxForm
                      model=".early_checkin_never"
                      label={formatMessage(checkinMessages.earlyCheckinNever)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".early_checkin_sometimes"
                      id="earlyCheckinSometimes"
                      name="earlyCheckinSometimes"
                      label={formatMessage(
                        checkinMessages.earlyCheckinSometimes
                      )}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".early_checkin_alternative"
                      label={formatMessage(
                        checkinMessages.earlyCheckinAlternative
                      )}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".bag_storage_allowed"
                      label={formatMessage(checkinMessages.bagStorageAllowed)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <Checkbox
                      name={formatMessage(checkinMessages.enterYourOwn)}
                      id={formatMessage(checkinMessages.enterYourOwn)}
                      label={formatMessage(checkinMessages.enterYourOwn)}
                      onChange={this.onEarlyCheckChange}
                      checked={this.state.earlyCheckShowText}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    {enterEarlyText}
                  </div>
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Select the statements that best describe how your guests
                      will gain access to the property
                    </h2>
                    <CheckBoxForm
                      model=".self_access_lockbox"
                      label={formatMessage(checkinMessages.selfAccessLockbox)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".self_access_meet"
                      label={formatMessage(checkinMessages.selfAccessMeet)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".self_access_codes"
                      label={formatMessage(checkinMessages.selfAccessCodes)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".self_access_email"
                      label={formatMessage(checkinMessages.selfAccessEmail)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                    <CheckBoxForm
                      model=".self_access_keyless"
                      label={formatMessage(checkinMessages.selfAccessKeyless)}
                      className="hf-taller-selection-control-container hf-wider-selection-control-container"
                    />
                  </div>
                  {user.canUseSecurity ? (
                    <div className="md-cell md-cell--12 hf-headline-margin">
                      <h2 className="md-headline">
                        Do you want to include restricted access details?
                      </h2>
                      <p>
                        If you choose yes, you can define a separate set of
                        instructions for guests who are viewing the guidebook
                        with a secure link.
                      </p>
                      <div className="md-grid md-grid--no-spacing">
                        <div className="md-cell md-cell--1 md-cell--1-phone">
                          <p className="md-text md-text-right hf-switch-left">
                            No
                          </p>
                        </div>
                        <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                          <Switch
                            id="checkin_has_secure_info"
                            name="checkin_has_secure_info"
                            checked={edit_checkin.checkin_has_secure_info}
                            onChange={this.handleCheckinHasSecure}
                            label="Yes"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {user.canUseSecurity &&
                  edit_checkin.checkin_has_secure_info ? (
                    <div className="md-cell md-cell--12 hf-headline-margin">
                      <h2 className="md-headline">
                        Enter your restricted information here:
                      </h2>
                      <p className="md-body-1">
                        Guests viewing your guidebook with a secure link will
                        see this information, while guests viewing your
                        guidebook with the normal link will see the public
                        information (next section below this one). &nbsp;
                        <a
                          className="hf-grey-link"
                          target="_blank"
                          href="/host/tokens"
                        >
                          You can create and edit secure links here.
                        </a>
                      </p>
                      <MultilangWysiwyg
                        model="edit_checkin"
                        field="checkin_text_secure"
                        txn_field="checkin_text_secure_txn"
                        locales={locales}
                        availableVariables={availableVariables}
                      />
                    </div>
                  ) : null}
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">{accessPrompt}</h2>
                    <p className="md-body-1">
                      Do not include any secure information in this field, as
                      this info will be seen by guests who are{' '}
                      <strong>NOT</strong> viewing the guidebook securely.
                    </p>
                    <MultilangWysiwyg
                      model="edit_checkin"
                      field="checkin_text"
                      txn_field="checkin_text_txn"
                      locales={locales}
                    />
                  </div>
                  {edit_checkin.access_image ? (
                    <div className="md-cell md-cell--12 hf-headline-margin">
                      <h2 className="md-headline">
                        Do you have an image to assist guests in gaining access?
                      </h2>
                      <p className="md-body-1 error">
                        Notice: This is a deprecated field and will eventually
                        be removed.
                        <br />
                        You're better off including any image(s) you want to use
                        in the rich text box(es) above.
                      </p>
                      <div className="md-grid">
                        <div className="md-cell md-cell--4">
                          {preview_image}
                        </div>
                        <div className="md-cell md-cell--8">{removeButton}</div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <VoiceForm editModel="edit_checkin" locales={locales} />
                <div className="md-grid">
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    {user.isEnterprise ? (
                      <ManageGuidebooksAndTemplates
                        ownerPluralName="checkins"
                        ownerSingularName="checkin"
                        guidebookId={guidebookId}
                        cardType="Checkin"
                        activeTab={ownerPluralName}
                        replaceWarning={true}
                        active={true}
                      />
                    ) : (
                      <ManageSelectedGuidebooks
                        ownerPluralName="checkins"
                        ownerSingularName="checkin"
                        guidebookId={guidebookId}
                        cardType="Checkin"
                        replaceWarning={true}
                        active={true}
                      />
                    )}
                  </div>
                </div>
                <div className="md-grid hf-headline-margin">
                  <div className="md-cell md-cell--12">
                    <div>{formErrors['formwide']}</div>
                    <div>{formErrors['label']}</div>
                    <div>{formErrors['checkin_time']}</div>
                  </div>
                </div>
                <FormCardActions
                  guidebookId={guidebookId}
                  saveAndAdd={this.saveAndAddAnother}
                />
              </Form>
            </Paper>
          </div>
        </DocumentTitle>
      </div>
    )
  }
}

CheckinForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_checkin = state.edit_checkin
  const edit_form = state.forms.edit_checkin
  const owner_data = state[`edit_${ownerSingularName}`]
  return {
    edit_checkin,
    owner_data,
    edit_form
  }
}

export default connect(mapStateToProps)(CheckinForm)
