import Breakjs from 'breakjs'
import React, { Component } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import DocumentTitle from 'react-document-title'
import {
  Button,
  DatePicker,
  DialogContainer,
  FontIcon,
  Paper,
  SelectField,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { replace } from 'react-router-redux'

import HfSwitchForm from 'components/HfSwitchForm'
import InputTextField from 'components/InputTextField'
import SelectForm from 'components/SelectForm'
import TooltipButton from 'components/TooltipButton'
import TooltipFontIcon from 'components/TooltipFontIcon'
import TooltipSpan from 'components/TooltipSpan'
import { getLabelForField, tokenFields } from 'constants/UIConstants'
import { fetchItems, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { addToast } from 'redux/modules/toast'
import currentUser from 'utils/CurrentUser'
import { filterData, sortData } from 'utils/Data'
import { stringToId } from 'utils/Strings'
import { buildTokenUrl } from 'utils/Urls'

const breakLayout = Breakjs({
  mobile: 0,
  phablet: 550,
  desktop: 1024,
  superwide: 1500
})

class TokenForm extends Component {
  constructor(props) {
    super(props)
    this.dataKeys = tokenFields
    this.state = {
      dataFormVisible: false,
      dataEditKey: this.dataKeys[0].key,
      confirmDeleteKey: null,
      editMode: false,
      dataKeyError: '',
      dataValue: '',
      dataValueError: '',
      confirmDeleteVisible: false
    }
  }

  componentDidMount() {
    const { dispatch, itemId } = this.props
    dispatch(fetchItems('guidebooks'))
    if (itemId === 'create') {
      this.loadNewItem()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, edit_token } = this.props
    const nextToken = nextProps.edit_token
    // make sure this user can edit this item
    if (!edit_token.id && nextToken.id) {
      const user = currentUser()
      // admins can edit categories that are not theirs if they are default
      const canEdit = user.id === nextToken.user_id || user.isAdmin
      if (!canEdit) {
        dispatch(replace('/403'))
      }
    }
  }

  loadNewItem = () => {
    const { dispatch } = this.props
    const newItem = Object.assign({}, blankEditStates.token.data)
    // make date objects with no time
    let now = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    let aWeek = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    aWeek = new Date(aWeek.setDate(aWeek.getDate() + 7))
    newItem.start_date = now.toISOString()
    newItem.end_date = aWeek.toISOString()
    dispatch(actions.load('edit_token', newItem))
  }

  back = () => {
    const { dispatch, pluralName } = this.props
    dispatch(showItems(pluralName))
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_token'))
  }

  handleSubmit = (item) => {
    const { itemId, dispatch } = this.props
    const addAnother = this.addAnother
    this.addAnother = false
    dispatch(saveItem('tokens', 'token', itemId, item, addAnother))
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_token'))
  }

  handleDateChange = (field, dateString, dateObject, event) => {
    const { dispatch } = this.props
    const utcDate = dateObject.toISOString()

    dispatch(actions.change(`edit_token.${field}`, utcDate))
    dispatch(actions.resetValidity(`edit_token.${field}`))
    dispatch(actions.setTouched(`edit_token.${field}`))
  }

  copyUrl = (e) => {
    this.props.dispatch(addToast('The URL has been copied to the clipboard.'))
  }

  updateGuidebookRef = (value) => {
    const { dispatch, guidebooks } = this.props
    let matches = filterData(
      guidebooks.data,
      'guidebooks',
      ['id'],
      parseInt(value, 10)
    )
    if (matches.length) {
      const gb = {
        name: matches[0].name,
        key: matches[0].key,
        id: matches[0].id,
        image: matches[0].image
      }
      dispatch(actions.change(`edit_token.guidebook`, gb))
    }
  }

  displaySecureUrl = () => {
    const self = this
    const { edit_token } = this.props
    let secureUrl = null
    if (edit_token.guidebook && edit_token.guidebook.key && edit_token.token) {
      const domain =
        edit_token.guidebook.domain &&
        edit_token.guidebook.domain.status === 'approved'
          ? edit_token.guidebook.domain.domain
          : null
      const gbUrl = buildTokenUrl(
        edit_token.guidebook.key,
        edit_token.token,
        null,
        domain
      )
      secureUrl = (
        <span>
          <TooltipSpan
            tooltipLabel="Click to copy URL to clipboard"
            tooltipPosition="right"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <CopyToClipboard
              className="show-pointer hf-copy-link"
              text={gbUrl}
              onCopy={(e) => {
                self.copyUrl(e)
              }}
            >
              <span>{gbUrl}</span>
            </CopyToClipboard>
          </TooltipSpan>
        </span>
      )
    }
    return secureUrl ? (
      <div
        key="display_link"
        className="md-cell md-cell--12 hf-headline-margin"
      >
        <h2 className="md-headline">Your secure link</h2>
        <p>
          Share this link with your guest to allow them to view restricted
          access to your guidebook. Click the link below to copy it
          automatically, or hightlight it and copy it manually.
        </p>
        {secureUrl}
      </div>
    ) : null
  }

  saveTokenData = () => {
    const newData = Object.assign({}, this.props.edit_token.data)
    const { dataEditKey, dataValue } = this.state
    if (dataEditKey.length > 0 && dataValue.length > 0) {
      this.toggleTokenData()
      newData[dataEditKey] = dataValue
      this.setState(
        {
          dataValue: '',
          dataEditKey: this.dataKeys[0].key
        },
        () => {
          this.props.dispatch(actions.change('edit_token.data', newData))
        }
      )
    } else {
      if (dataEditKey.length === 0) {
        this.setState({ dataKeyError: 'please select a data field' })
      }
      if (dataValue.length === 0) {
        this.setState({ dataValueError: 'please enter a value for this data' })
      }
    }
  }

  deleteDataKey = () => {
    const self = this
    const newData = Object.assign({}, this.props.edit_token.data)
    const { confirmDeleteKey } = this.state
    delete newData[confirmDeleteKey]
    this.hideConfirmDeleteKey(() => {
      self.props.dispatch(actions.change('edit_token.data', newData))
    })
  }

  editTokenData = (key) => {
    const self = this
    this.setState({
      dataValue: self.props.edit_token.data[key],
      dataEditKey: key,
      editMode: true,
      dataFormVisible: true
    })
  }

  toggleTokenData = (a) => {
    if (this.state.dataFormVisible) {
      // if closing, clear the fields
      this.setState({
        dataFormVisible: false,
        dataEditKey: null,
        dataKeyError: null,
        dataValue: '',
        dataValueError: '',
        editMode: false
      })
    } else {
      const unusedDataFields = this.getUnusedDataFields()
      this.setState({
        editMode: false,
        dataFormVisible: true,
        dataEditKey: unusedDataFields[0].key
      })
    }
  }

  handleKeyChange = (key) => {
    const error = key.length ? '' : 'please select a data field'
    this.setState({ dataEditKey: key, dataKeyError: error })
  }

  handleDataValueChange = (value) => {
    const error = value.length ? '' : 'please enter a value'
    this.setState({ dataValue: value, dataValueError: error })
  }

  confirmDeleteDataKey = (key) => {
    this.setState({ confirmDeleteKey: key, confirmDeleteVisible: true })
  }

  hideConfirmDeleteKey = (cb) => {
    this.setState(
      { confirmDeleteKey: null, confirmDeleteVisible: false },
      () => {
        if (typeof cb === 'function') {
          cb()
        }
      }
    )
  }

  getUnusedDataFields = () => {
    const self = this
    return self.dataKeys.filter((item) => {
      if (item.key === '') return false
      if (
        self.props.edit_token.data &&
        self.props.edit_token.data.hasOwnProperty(item.key)
      )
        return false
      return true
    })
  }

  renderCurrentTokenData = () => {
    const self = this
    const tokenData = this.props.edit_token.data
    if (tokenData && Object.keys(tokenData).length) {
      const fieldRows = Object.keys(tokenData).map((key, idx) => {
        const evenOdd = idx % 2 === 1 ? 'even' : 'odd'
        const value = tokenData[key]
        return [
          <div
            className={`md-cell md-cell--3 md-cell--2-tablet md-cell--2-phone row-${evenOdd}`}
            key={`cell-${stringToId(key)}-key`}
          >
            {getLabelForField(key)}
          </div>,
          <div
            className={`md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone row-${evenOdd}`}
            key={`cell-${stringToId(key)}-value`}
          >
            {value}
          </div>,
          <div
            className={`md-cell md-cell--4 md-cell--3-tablet md-cell--4-phone md-text-right row-${evenOdd}`}
            key={`cell-${stringToId(key)}-spacer`}
          >
            <TooltipFontIcon
              className="action-icon"
              tooltipLabel="Edit"
              tooltipPosition="top"
              onClick={() => {
                self.editTokenData(key)
              }}
            >
              edit
            </TooltipFontIcon>
            <TooltipFontIcon
              className="action-icon"
              tooltipLabel="Delete Custom Field"
              tooltipPosition="top"
              onClick={() => {
                self.confirmDeleteDataKey(key)
              }}
            >
              delete
            </TooltipFontIcon>
          </div>
        ]
      })
      return (
        <div className="md-cell md-cell--12 splash-field-data">
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--3 md-cell--2-tablet md-cell--2-phone row-header">
              <strong>Key</strong>
            </div>
            <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--2-phone row-header">
              <strong>Value</strong>
            </div>
            <div className="md-cell md-cell--4 md-cell--2-tablet md-cell--phone-hidden row-header" />
            {fieldRows}
          </div>
        </div>
      )
    } else {
      return (
        <div className="md-cell md-cell--12">
          You have not yet added any guest data
        </div>
      )
    }
  }

  renderTokenData = () => {
    const self = this

    const unusedDataFields = self.getUnusedDataFields()
    const displayFields = self.state.editMode ? self.dataKeys : unusedDataFields
    const topMargin = { marginTop: '12px' }
    const addDataActions = [
      <Button key="btn-save-field" raised primary onClick={self.saveTokenData}>
        Save
      </Button>,
      <Button
        key="btn-cancel-field"
        raised
        secondary
        onClick={self.toggleTokenData}
      >
        Cancel
      </Button>
    ]

    const layout = breakLayout.current()
    const addDataDialog = (
      <DialogContainer
        id="addDataDialog"
        visible={self.state.dataFormVisible}
        actions={addDataActions}
        onHide={self.toggleTokenData}
        title={self.state.editMode ? 'Edit link data' : 'Add link data'}
        width={layout === 'mobile' ? '90vw' : 500}
      >
        <div className="md-grid">
          <SelectField
            id="dataKey"
            className="md-cell md-cell--12"
            label="Select a data field"
            placeholder="Select a field"
            menuItems={displayFields}
            value={
              self.state.dataEditKey ||
              (displayFields.length ? displayFields[0].key : '')
            }
            simplifiedMenu={false}
            disabled={self.state.editMode}
            onChange={(value) => {
              self.handleKeyChange(value)
            }}
            error={self.state.dataKeyError ? true : false}
            errorText={self.state.dataKeyError}
            itemLabel="label"
            itemValue="key"
          >
            {displayFields}
          </SelectField>
          <TextField
            id="dataValue"
            name="dataValue"
            label="Data Value"
            className="md-cell md-cell--12"
            value={self.state.dataValue}
            onChange={(value) => {
              self.handleDataValueChange(value)
            }}
            error={self.state.dataValueError ? true : false}
            errorText={self.state.dataValueError}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                return self.saveTokenData()
              }
            }}
          />
        </div>
      </DialogContainer>
    )

    const confirmDeleteActions = [
      <Button key="btn-save-field" raised primary onClick={self.deleteDataKey}>
        Yes, Delete it.
      </Button>,
      <Button
        key="btn-cancel-field"
        raised
        secondary
        onClick={self.hideConfirmDeleteKey}
      >
        Cancel
      </Button>
    ]

    const deleteKeyDialog = (
      <DialogContainer
        id="confirmDeleteKey"
        visible={this.state.confirmDeleteVisible}
        actions={confirmDeleteActions}
        onHide={this.hideConfirmDeleteKey}
        title="Delete Data Item?"
      >
        Are you sure you wish to delete this data field from the custom link?
      </DialogContainer>
    )

    const addDataButton = unusedDataFields.length ? (
      <Button onClick={this.toggleTokenData} secondary raised>
        Add guest data
      </Button>
    ) : null

    return (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12">
            <h2 className="md-headline">
              Do you have guest data to add to this link?
            </h2>
            <p>
              You can assign guest data to this link and that data will be
              available to use in the rich text fields of your guidebook.
            </p>
          </div>
          {this.renderCurrentTokenData()}
          {addDataDialog}
          {deleteKeyDialog}
          <div className="md-cell md-cell--12" style={topMargin}>
            {addDataButton}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const self = this
    const { edit_token, copy } = this.props
    const title = copy
      ? 'Copy secure link'
      : edit_token.id
        ? 'Edit secure link'
        : 'New secure link'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.back}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []

    const validators = {
      start_date: {
        required: (val) => val && val.length,
        valid: (val) => {
          const sd = new Date(val)
          const ed = new Date(edit_token.end_date)
          return sd < ed
        }
      },
      end_date: {
        required: (val) => val && val.length,
        valid: (val) => {
          const ed = new Date(val)
          const sd = new Date(edit_token.start_date)
          return ed > sd
        }
      },
      label: {
        required: (val) => val && val.length
      },
      'guidebook.id': {
        required: (val) => val && val > 0
      }
    }

    const formErrors = []
    const errorMessages = {
      start_date: {
        required: 'Please provide a start date',
        valid: 'Start date must be earlier than end date'
      },
      end_date: {
        required: 'Please provide an end date',
        valid: 'End date must be later than start date'
      },
      label: {
        required: 'Please provide a label for this secure link'
      },
      guidebook: {
        required: 'Please select a guidebook for this secure link'
      }
    }
    formErrors['start_date'] = (
      <Errors
        model=".start_date"
        messages={errorMessages.start_date}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['end_date'] = (
      <Errors
        model=".end_date"
        messages={errorMessages.end_date}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['guidebook'] = (
      <Errors
        model=".guidebook.id"
        messages={errorMessages.guidebook}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    // console.log(edit_token);
    let now = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    const default_start_date = edit_token.start_date
      ? new Date(edit_token.start_date)
      : now
    let aWeek = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    aWeek = new Date(aWeek.setDate(aWeek.getDate() + 7))
    const default_end_date = edit_token.end_date
      ? new Date(edit_token.end_date)
      : aWeek
    const guidebook_data = sortData(this.props.guidebooks.data, 'name').map(
      (item) => {
        const label = `${item.name} (${item.key})`
        return { label: label, id: item.id }
      }
    )
    return (
      <DocumentTitle title="Secure Link">
        <div className="hf-tokens-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-tokens"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="token" className="hf-form-wrapper">
            <Form
              model="edit_token"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div
                  key="token_label"
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">Create a label for this link</h2>
                  <p>
                    This label will help you differentiate your secure links.
                    Use something descriptive, or something to help associate
                    this link with a particular guest. Guests will not see this
                    label.
                  </p>
                  <div className="md-grid md-grid--no-spacing">
                    <InputTextField
                      model=".label"
                      id="label"
                      label="Label"
                      className="md-cell md-cell--8 md-cell--5-tablet"
                    />
                    <div className="md-cell md-cell--8 md-cell--5-tablet">
                      {formErrors['label']}
                    </div>
                  </div>
                </div>
                <div
                  key="token_guidebook"
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">
                    Which guidebook is this link for?
                  </h2>
                  <div className="md-grid md-grid--no-spacing">
                    <SelectForm
                      className="md-cell md-cell--8 md-cell--5-tablet"
                      label="Select a guidebook"
                      model=".guidebook.id"
                      includeNone={false}
                      itemLabel="label"
                      itemValue="id"
                      onChange={self.updateGuidebookRef}
                    >
                      {guidebook_data}
                    </SelectForm>
                    <div className="md-cell md-cell--8 md-cell--5-tablet">
                      {formErrors['guidebook']}
                    </div>
                  </div>
                </div>
                <div
                  key="token_start"
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">
                    When should this link start working?
                  </h2>
                  <p>
                    Before this date, guests using this link would see what they
                    normally would without a secure link.
                  </p>
                  <div className="md-grid md-grid--no-spacing">
                    <DatePicker
                      id="token_start_date"
                      label="Select a start date"
                      className="md-cell md-cell--8 md-cell--5-tablet"
                      closeYearOnSelect
                      autoOk
                      value={default_start_date}
                      onChange={(dateString, dateObject, event) => {
                        self.handleDateChange(
                          'start_date',
                          dateString,
                          dateObject,
                          event
                        )
                      }}
                    />
                    <div className="md-cell md-cell--8 md-cell--5-tablet">
                      {formErrors['start_date']}
                    </div>
                  </div>
                </div>
                <div
                  key="token_end"
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">
                    When should this link stop working?
                  </h2>
                  <p>
                    After this date, guests using this link would see what they
                    normally would without a secure link.
                  </p>
                  <div className="md-grid md-grid--no-spacing">
                    <DatePicker
                      id="token_end_date"
                      label="Select an end date"
                      className="md-cell md-cell--8 md-cell--5-tablet"
                      closeYearOnSelect
                      autoOk
                      value={default_end_date}
                      onChange={(dateString, dateObject, event) => {
                        self.handleDateChange(
                          'end_date',
                          dateString,
                          dateObject,
                          event
                        )
                      }}
                    />
                    <div className="md-cell md-cell--8 md-cell--5-tablet">
                      {formErrors['end_date']}
                    </div>
                  </div>
                </div>
                <div
                  key="token_enabled"
                  className="md-cell md-cell--12 hf-headline-margin"
                >
                  <h2 className="md-headline">Is this link enabled?</h2>
                  <p>
                    You may disable this link at any time by coming here and
                    selecting{' '}
                    <strong>
                      <em>No</em>
                    </strong>
                    .
                  </p>
                  <div className="md-grid md-grid--no-spacing">
                    <div className="md-cell md-cell--1 md-cell--1-phone">
                      <p className="md-text md-text-right hf-switch-left">No</p>
                    </div>
                    <div className="md-cell md-cell--1 md-cell--1-phone">
                      <HfSwitchForm
                        name="is_enabled"
                        editModel="edit_token"
                        model="is_enabled"
                        checked={edit_token.is_enabled}
                        label="Yes"
                        labelBefore
                      />
                    </div>
                  </div>
                </div>
                {self.renderTokenData()}
                {self.displaySecureUrl()}
              </div>
              <div className="md-grid hf-headline-margin hf-form-errors">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['start_date']}</div>
                  <div>{formErrors['end_date']}</div>
                  <div>{formErrors['label']}</div>
                  <div>{formErrors['guidebook']}</div>
                </div>
              </div>
              <div className="hf-sticky">
                <Button type="submit" primary raised>
                  Save token
                </Button>
                <Button
                  onClick={this.saveAndAddAnother}
                  type="button"
                  primary
                  raised
                >
                  Save and Add Another
                </Button>
              </div>
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

TokenForm.propTypes = {}
function mapStateToProps(state, props) {
  const edit_token = state.edit_token
  const listData = state.list
  return {
    edit_token,
    guidebooks: listData.guidebooks
  }
}

export default connect(mapStateToProps)(TokenForm)
